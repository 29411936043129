import React from "react";

import { useAppDispatch, useAppSelector } from "store";
import { Card, CardActionArea, CardContent, Grid, IconButton, Typography } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { useTheme, withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";
import SimpleTooltip, { SimpleTooltipPlacement } from "components/SimpleTooltip";

import { clearCandidateRange, clearCandidateStore, selectIsTooltipOpen, selectOpenModalActiveStep, selectTooltipHintText, setActiveStep, showStoreRangeFilters } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import { clearSelectedRange, clearSelectedStore, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.background.insight,
        borderRadius: 6
    }
}))(Card);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        minHeight: theme.spacing(10)
    }
}))(CardActionArea);

const Open: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const selectedStore = useAppSelector(selectSelectedStore);
    const selectedRange = useAppSelector(selectSelectedRange);
    const tooltipText = useAppSelector(selectTooltipHintText);
    const isTooltipOpen = useAppSelector(selectIsTooltipOpen);
    const openModalActiveStep = useAppSelector(selectOpenModalActiveStep);

    const handleClickOpenModal = () => {
        dispatch(setActiveStep(openModalActiveStep));
        dispatch(showStoreRangeFilters());
    };

    const handleClickClearStore = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(clearSelectedStore());
        dispatch(clearCandidateStore());
    };

    const handleClickClearRange = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(clearSelectedRange());
        dispatch(clearCandidateRange());
    };

    return (
        <SimpleTooltip
            placement={SimpleTooltipPlacement.Bottom}
            title={
                <Typography variant="subtitle1" component="div">
                    {tooltipText}
                </Typography>
            }
            open={isTooltipOpen}
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
        >
            <StyledCard
                elevation={0}
                variant="outlined"
                data-cy="btn-filters"
            >
                <StyledCardActionArea onClick={handleClickOpenModal}>
                    <CardContent>
                        <Grid container direction="column">
                            <Grid container style={{ marginBottom: theme.spacing(1) }} alignItems="center">
                                <Grid item md={3}>
                                    <Typography variant="subtitle1" component="div">
                                        Store:
                                    </Typography>
                                </Grid>
                                <Grid item md={8}>
                                    {selectedStore ? (
                                        <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                                            {selectedStore?.name}
                                        </Typography>
                                    ) : (
                                        <Typography variant="h6" component="div" style={{ color: theme.palette.text.secondary }}>
                                            Unselected
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item md={1}>
                                    {selectedStore && (
                                        <IconButton
                                            size="small"
                                            onClick={handleClickClearStore}
                                        >
                                            <Close />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item md={3}>
                                    <Typography variant="subtitle1" component="div">
                                        Range:&nbsp;&nbsp;
                                    </Typography>
                                </Grid>
                                <Grid item md={8}>
                                    {selectedRange ? (
                                        <Typography variant="h6" component="div" style={{ color: colourPalette.comparators[0] }}>
                                            {selectedRange?.name}
                                        </Typography>
                                    ) : (
                                        <Typography variant="h6" component="div" style={{ color: theme.palette.text.secondary }}>
                                            Unselected
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item md={1}>
                                    {selectedRange && (
                                        <IconButton
                                            size="small"
                                            onClick={handleClickClearRange}
                                        >
                                            <Close />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </StyledCardActionArea>
            </StyledCard>
        </SimpleTooltip>
    );
};

export default Open;
