import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/tools/product/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { BinaryFilter, Query, ResultSet } from "@cubejs-client/core";

export class Competitor {
    public readonly storeId: string;
    public readonly fascia: string;
    public readonly directCompetitor: boolean;
    public readonly dateOpened: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly size: number;

    constructor(
        storeId: string,
        fascia: string,
        directCompetitor: boolean,
        dateOpened: string,
        latitude: number,
        longitude: number,
        size: number,
    ) {
        this.storeId = storeId;
        this.fascia = fascia;
        this.directCompetitor = directCompetitor;
        this.dateOpened = dateOpened;
        this.latitude = latitude;
        this.longitude = longitude;
        this.size = size;
    }
}

export const loadCompetitors = (store?: Store): AppThunk<Promise<Competitor[]>> => async (dispatch) => {
    try {
        if (!store) {
            return [];
        }

        const directCompetitorNamesQuery = {
            dimensions: [
                "F_DirectCompetitors.CompetitorName"
            ]
        };
        const directCompetitorNamesResultSet = await dispatch(cubeLoad(directCompetitorNamesQuery)) as unknown as ResultSet;
        const directCompetitorNamesRawData = directCompetitorNamesResultSet.rawData();
        const directCompetitorNames: string[] = directCompetitorNamesRawData.length !== 0
            ? directCompetitorNamesRawData.map(item => item["F_DirectCompetitors.CompetitorName"].toUpperCase())
            : [];

        const orClause: BinaryFilter[] = [{
            member: "LocalCompetitors.StoreCategory_ID",
            operator: "equals",
            values: [String(store.storeCategoryID)]
        }];
        if (directCompetitorNames.length !== 0) {
            orClause.push({
                member: "FasciaMapping.RevisedFascia",
                operator: "equals",
                values: directCompetitorNames
            });
        }
        const query: Query = {
            dimensions: [
                "LocalCompetitors.Fascia",
                "LocalCompetitors.StoreCategory_ID",
                "LocalCompetitors.RetailCentreID",
                "LocalCompetitors.OpeningDate",
                "FasciaMapping.RevisedFascia",
                "LocalCompetitors.LAT",
                "LocalCompetitors.LNG",
                "LocalCompetitors.NetSalesAreaSqFt"
            ],
            filters: [{
                member: "LocalCompetitors.Distance",
                operator: "lt",
                values: ["7"]
            }, {
                member: "LocalCompetitors.DateDeleted",
                operator: "notSet"
            }, {
                member: "LocalCompetitors.RetailCentreID",
                operator: "equals",
                values: [String(store.retailCentreID)]
            }, {
                or: orClause
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;

        const competitors = resultSet.rawData().map(row => new Competitor(
            store.id,
            row["LocalCompetitors.Fascia"],
            directCompetitorNames.includes(row["FasciaMapping.RevisedFascia"]),
            (DateTime.fromISO(row["LocalCompetitors.OpeningDate"], { zone: "utc" })).toISODate(),
            Number(row["LocalCompetitors.LAT"]),
            Number(row["LocalCompetitors.LNG"]),
            Number(row["LocalCompetitors.NetSalesAreaSqFt"])
        ));
        return competitors;
    } catch (error) {
        dispatch(logError("Error loading Competitors.", error));
        throw error;
    }
};
