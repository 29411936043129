import React from "react";

import { Card, CardContent, Chip, Collapse, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

import { CatchmentCustomerProfile } from "modules/customer/tools/product/productStoreFit/catchmentCustomerProfile";
import numberFormatter from "utils/numberFormatter";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(31),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledChip = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-background-color"],
        width: theme.spacing(3),
        height: theme.spacing(1.5)
    }
}))(Chip);

const StyledDivider = withStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(Divider);

interface LegendProps {
    catchmentCustomerProfile?: CatchmentCustomerProfile
}

const Legend: React.FC<LegendProps> = (props) => {
    const { catchmentCustomerProfile } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();

    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Collapse in={catchmentCustomerProfile !== undefined}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <Typography variant="body2" component="div">
                                Likelihood of visiting
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" component="div" align="right">
                                {numberFormatter.toPercentage(catchmentCustomerProfile?.probability ?? 0, false, 0)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" component="div">
                                Supergroup
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" component="div" align="right" noWrap>
                                {catchmentCustomerProfile?.supergroupName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" component="div">
                                Group
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" component="div" align="right" noWrap>
                                {catchmentCustomerProfile?.groupName}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" component="div">
                                Subgroup
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle2" component="div" align="right" noWrap>
                                {catchmentCustomerProfile?.subgroupName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <StyledDivider />
                </Collapse>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    Catchment area customer profiles
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[0]} />
                    &nbsp;&nbsp;Rural residents
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[1]} />
                    &nbsp;&nbsp;Cosmopolitans
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[2]} />
                    &nbsp;&nbsp;Ethnicity central
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[3]} />
                    &nbsp;&nbsp;Multicultural metropolitans
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[4]} />
                    &nbsp;&nbsp;Urbanites
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[5]} />
                    &nbsp;&nbsp;Suburbanites
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[6]} />
                    &nbsp;&nbsp;Constrained city dwellers
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.categorical[7]} />
                    &nbsp;&nbsp;Hard-pressed living
                </Typography>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
