import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import { selectDistanceToCoreRetailHub } from "modules/customer/tools/product/productStoreFit/productStoreFitSlice";
import { selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const DistanceToCoreRetailHub: React.FC = () => {
    const distanceToCoreRetailHub = useAppSelector(selectDistanceToCoreRetailHub);
    const isLoading = distanceToCoreRetailHub.isLoading;
    const hasErrors = distanceToCoreRetailHub.hasErrors;
    const value = distanceToCoreRetailHub.data.value;
    const valueFormatted = `${numberFormatter.toDecimalPlaces(value, 1)}km`;
    const store = useAppSelector(selectSelectedStore);
    const label = `${store?.name} store's distance to core retail hub`;

    return (
        <Box width="100%" height="100%" data-cy="distance-to-core-retail-hub">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default DistanceToCoreRetailHub;
