import React from "react";

import TwoPanelDialog, { JustifyContent } from "components/TwoPanelDialog";

import Actions from "./Actions";
import Content from "./Content";
import Open from "./Open";
import Stepper from "./Stepper";
import Title from "./Title";

import { useAppDispatch, useAppSelector } from "store";
import { hideStoreRangeFilters, selectStoreRangeFiltersVisibility } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";

const StoreRange: React.FC = () => {
    const dispatch = useAppDispatch();
    const filtersVisibility = useAppSelector(selectStoreRangeFiltersVisibility);
    const isVisible = filtersVisibility.isVisible;

    const handleClose = () => {
        dispatch(hideStoreRangeFilters());
    };

    return (
        <>
            <Open />
            <TwoPanelDialog
                open={isVisible}
                onClose={handleClose}
                leftPanelTitle={<Title />}
                leftPanelContent={<Stepper />}
                leftPanelActions={<Actions />}
                leftPanelActionsJustifyContent={JustifyContent.SpaceBetween}
                rightPanelContent={<Content />}
            />
        </>
    );
};

export default StoreRange;
