import React from "react";

const Subtitle: React.FC = () => {
    return (
        <>
            Get a feel for the customers in your selected location's catchment area and understand how they spend.
            Investigate whether the people that spend the most on the products you stock are the type of people that
            would traditionally come to your stores.
        </>
    );
};

export default Subtitle;
