import { selectSelectedPartner, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import React from "react";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedRange = useAppSelector(selectSelectedRange);
    const selectedStore = useAppSelector(selectSelectedStore);
    return (
        <>
            Explore the local customer base to understand how well the demographics around the {selectedPartner?.name} {selectedStore?.name} store
            align with an ideal location for {selectedRange?.name}. Uncover the factors that make this product a strong or weak fit.
        </>
    );
};

export default Subtitle;
