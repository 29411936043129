import { Query, ResultSet } from "@cubejs-client/core";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { RetailCentre, StaticRetailCentre } from "modules/customer/tools/location/retailCentre";
import { SpendCategory } from "modules/customer/tools/location/spendCategory";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class Store {
    public readonly id: string;
    public readonly name: string;
    public readonly categoryId: number;
    public readonly categoryName: string;
    public readonly group: string;
    public readonly revenue: number;
    public readonly retailCentre: RetailCentre;
    public readonly latitude: number;
    public readonly longitude: number;

    constructor(
        id: string,
        name: string,
        categoryId: number,
        categoryName: string,
        group: string,
        revenue: number,
        retailCentre: RetailCentre,
        latitude: number,
        longitude: number
    ) {
        this.id = id;
        this.name = name;
        this.categoryId = categoryId;
        this.categoryName = categoryName;
        this.group = group;
        this.revenue = revenue;
        this.retailCentre = retailCentre;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export const loadStores = (
    salesReferenceDate: DateTime,
    staticRetailCentres: Map<number, StaticRetailCentre>,
    spendCategories: SpendCategory[],
    enableSpendNew: boolean,
    catchmentAccountId: string
): AppThunk<Promise<Store[]>> => async (dispatch) => {
    if (staticRetailCentres.size === 0) {
        return [];
    }

    try {
        const lastFullMonth = salesReferenceDate.minus({ months: 1 }).endOf("month");
        const twelveMonthsPriorToLastFullMonth = lastFullMonth.minus({ years: 1 }).plus({ days: 1 }).startOf("day");

        const storesQuery = {
            measures: ["F_Sales.SumLineValue"],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [twelveMonthsPriorToLastFullMonth, lastFullMonth]
                ]
            }],
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName",
                "D_Store.RetailCentreID",
                "D_Store.StoreCategory_ID",
                "D_Store.KPMGStoreCategory",
                "D_Store.Group",
                "D_Store.Lat",
                "D_Store.Long"
            ],
            filters: [{
                member: "D_Store.ClosingDate",
                operator: "notSet"
            }]
        };
        const storesResultSet = await dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
        const storesResultSetDecomposed = storesResultSet.decompose().map(result => result.rawData());
        if (storesResultSetDecomposed.length === 0) {
            return [];
        }
        const rawStores = storesResultSetDecomposed[0];

        const retailCentreIdsByStoreCategoryId = new Map<number, number[]>();
        rawStores.forEach(rawStore => {
            const retailCentreId = Number(rawStore["D_Store.RetailCentreID"]);
            const storeCategoryId = Number(rawStore["D_Store.StoreCategory_ID"]);
            const retailCentreIds = retailCentreIdsByStoreCategoryId.get(storeCategoryId) ?? [];
            retailCentreIds.push(retailCentreId);
            retailCentreIdsByStoreCategoryId.set(storeCategoryId, retailCentreIds);
        });

        const orFilterClause = { or: [] };
        retailCentreIdsByStoreCategoryId.forEach((retailCentreIds, storeCategoryId) => {
            const andFilterClause = {
                and: [{
                    member: "LocationBenchmarkMetrics.StoreCategory_ID",
                    operator: "equals",
                    values: [String(storeCategoryId)]
                }, {
                    member: "LocationBenchmarkMetrics.RetailCentreID",
                    operator: "equals",
                    values: retailCentreIds.map(String)
                }]
            };
            // @ts-ignore
            orFilterClause.or.push(andFilterClause);
        });

        const retailCentresQuery: Query = {
            dimensions: [
                "LocationBenchmarkMetrics.RetailCentreID",
                "LocationBenchmarkMetrics.StoreCategory_ID",
                "LocationBenchmarkMetrics.AffluenceCentile",
                "LocationBenchmarkMetrics.AgeCentile",
                "LocationBenchmarkMetrics.ChildrenCentile",
                "LocationBenchmarkMetrics.DiversityCentile",
                "LocationBenchmarkMetrics.UrbanicityCentile",
                "LocationBenchmarkMetrics.AreaHealthCentile",
                "LocationBenchmarkMetrics.YoYNetOpeningsPercentage",
                "LocationBenchmarkMetrics.FootfallCentile",
                "RetailCentreClassification.RetailCentreClassificationName"
            ],
            filters: [orFilterClause, {
                member: "LocationBenchmarkMetrics.Client_ID",
                operator: "equals",
                values: [catchmentAccountId]
            }],
            segments: [
                "LocationBenchmarkMetrics.Baseline"
            ]
        };

        if (enableSpendNew) {
            retailCentresQuery.measures = ["LocationBenchmarkSpendMetrics.MeanSpendCentile"];
            retailCentresQuery.filters?.push({
                member: "LocationBenchmarkSpendMetrics.SpendCategory_ID",
                operator: "equals",
                values: spendCategories.map(spendCategory => String(spendCategory.id))
            });
        }

        const retailCentreResultSet = await dispatch(cubeLoad(retailCentresQuery)) as unknown as ResultSet;
        const rawRetailCentres = retailCentreResultSet.rawData();
        const retailCentres: RetailCentre[] = [];
        rawRetailCentres.forEach(rawRetailCentre => {
            const retailCentreId = Number(rawRetailCentre["LocationBenchmarkMetrics.RetailCentreID"]);
            const staticRetailCentre = staticRetailCentres.get(retailCentreId);
            if (staticRetailCentre) {
                const retailCentre = new RetailCentre(
                    staticRetailCentre,
                    Number(rawRetailCentre["LocationBenchmarkMetrics.StoreCategory_ID"]),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.AffluenceCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.AgeCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.ChildrenCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.DiversityCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.UrbanicityCentile"] ?? 0),
                    enableSpendNew ? Number(rawRetailCentre["LocationBenchmarkSpendMetrics.MeanSpendCentile"] ?? 0) : 0,
                    Number(rawRetailCentre["LocationBenchmarkMetrics.AreaHealthCentile"] ?? 0),
                    Boolean(rawRetailCentre["LocationBenchmarkMetrics.YoYNetOpeningsPercentage"] === null),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.FootfallCentile"] ?? 0),
                    String(rawRetailCentre["RetailCentreClassification.RetailCentreClassificationName"] ?? null)
                );
                retailCentres.push(retailCentre);
            }
        });

        const stores: Store[] = [];
        rawStores.forEach(rawStore => {
            const retailCentreId = Number(rawStore["D_Store.RetailCentreID"]);
            const retailCentre = retailCentres.find(rc => (rc.id === retailCentreId) && (rc.storeCategoryId === Number(rawStore["D_Store.StoreCategory_ID"])));
            if (retailCentre) {
                const id = String(rawStore["D_Store.StoreNaturalID"]);
                const name = String(rawStore["D_Store.StoreName"]);
                const categoryId = Number(rawStore["D_Store.StoreCategory_ID"]);
                const categoryName = String(rawStore["D_Store.KPMGStoreCategory"]);
                const group = String(rawStore["D_Store.Group"]);
                const revenue = Number(rawStore["F_Sales.SumLineValue"] ?? 0);
                const latitude = Number(rawStore["D_Store.Lat"]);
                const longitude = Number(rawStore["D_Store.Long"]);
                const store = new Store(id, name, categoryId, categoryName, group, revenue, retailCentre, latitude, longitude);
                stores.push(store);
            }
        });

        return stores;
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};
