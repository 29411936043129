import { Button, useTheme, withStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore, GetApp } from "@material-ui/icons";
import { DataGridPro, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro";
import Spacer from "components/Spacer";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectCatchmentCustomerProfilesColumns, selectCostsColumns, selectProfitDriversColumns, selectStoresWithAllMetrics } from "modules/customer/tools/report/reportSlice";
import React from "react";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledDataGrid = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        '& .MuiCheckbox-root': {
            color: theme.palette.text.primary,
            '&.Mui-checked': {
                //@ts-ignore
                color: theme.palette.tertiary.main,
            },
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.16)',
            },
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        }
    }
}))(DataGridPro);

const ExportTable: React.FC = () => {
    const theme = useTheme();
    const userInfo = useAppSelector(selectUserInfo);
    const stores = useAppSelector(selectStoresWithAllMetrics);
    const costsColumns = useAppSelector(selectCostsColumns);
    const profitDriversColumns = useAppSelector(selectProfitDriversColumns);
    const catchmentCustomerProfilesColumns = useAppSelector(selectCatchmentCustomerProfilesColumns);

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Store name',
            sortable: true,
        },
        {
            field: 'id',
            flex: 1,
            headerName: 'Store ID',
            sortable: true,
            hide: true
        },
        {
            field: 'kpmgStoreCategory',
            flex: 1,
            headerName: 'Store category',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'region',
            flex: 1,
            headerName: 'Region',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'clientRegion',
            flex: 1,
            headerName: `${userInfo.companyDisplayName} region`,
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'retailCentreClassificationName',
            flex: 1,
            headerName: 'Pitch type',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'sizeInSquareFeet',
            flex: 1,
            headerName: 'Sqftage',
            type: 'number',
            sortable: true,
        },
        {
            field: 'group',
            flex: 1,
            headerName: 'Store group',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'format',
            flex: 1,
            headerName: 'Format type',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'segment',
            flex: 1,
            headerName: 'Segment type',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'openingDate',
            type: 'date',
            flex: 1,
            headerName: 'Opening date',
            sortable: true,
            hide: true
        },
        {
            field: 'numberOfEmployees',
            flex: 1,
            headerName: 'Employees',
            type: 'number',
            sortable: true,
            hide: true
        },
        {
            field: 'weeklyRevenue',
            type: 'number',
            flex: 1,
            headerName: 'Average weekly revenue',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'yearlyRevenue',
            type: 'number',
            flex: 1,
            headerName: 'Revenue in the last year',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'forecastSales',
            type: 'number',
            flex: 1,
            headerName: 'Forecast revenue in the next 12 months',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'grossProfit',
            type: 'number',
            flex: 1,
            headerName: 'Gross profit',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'grossProfitMargin',
            type: 'number',
            flex: 1,
            headerName: 'Gross profit margin',
            sortable: true,
            renderCell: (params) => numberFormatter.toPercentage(params.value ?? 0, false),
        },
        {
            field: 'revenuePerSquareFoot',
            type: 'number',
            flex: 1,
            headerName: 'Revenue/ft2',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        ...costsColumns,
        {
            field: 'revenuePerHead',
            type: 'number',
            flex: 1,
            headerName: 'Revenue per head',
            sortable: true,
            hide: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        ...profitDriversColumns,
        {
            field: 'catchmentSize',
            type: 'number',
            flex: 1,
            headerName: 'Weighted catchment population',
            sortable: true,
            renderCell: (params) => numberFormatter.toDecimalPlaces(params.value ?? 0, 1),
        },
        {
            field: 'catchmentSpend',
            type: 'number',
            flex: 1,
            headerName: 'Catchment spend',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'numberOfCompetitors',
            type: 'number',
            flex: 1,
            headerName: 'Number of competitors',
            sortable: true,
        },
        {
            field: 'changeInNumberOfStores',
            type: 'number',
            flex: 1,
            headerName: '% change in number of stores',
            sortable: true,
            renderCell: (params) => numberFormatter.toPercentage(params.value ?? 0, false),
        },
        {
            field: 'footfallLevel',
            type: 'number',
            flex: 1,
            headerName: 'Footfall level',
            sortable: true,
            renderCell: (params) => numberFormatter.toDecimalPlaces(params.value ?? 0, 1),
        },
        ...catchmentCustomerProfilesColumns
    ];

    const columndsWithWidth = columns.map(column => {
        if (column.type === 'singleSelect') {
            //@ts-ignore
            column.valueOptions = Array.from(new Set(stores.map(store => store[column.field])));
        }
        return {
            ...column,
            minWidth: 200
        };
    });

    const apiRef = useGridApiRef();

    const handleExport = () => {
        apiRef.current.exportDataAsCsv({ fileName: "Dash stores export" });
    };

    const CustomExportButton = () => {
        return (
            <Button
                // @ts-ignore 
                style={{ color: theme.palette.tertiary.main }}
                startIcon={<GetApp />}
                onClick={handleExport}
                size="small"
            >
                Export
            </Button>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ paddingRight: theme.spacing(2) }}>
                <GridToolbarColumnsButton style={{ color: theme.palette.text.primary }} />
                <GridToolbarFilterButton style={{ color: theme.palette.text.primary }} />
                <Spacer />
                <CustomExportButton />
            </GridToolbarContainer>
        );
    }

    return (
        <StyledDataGrid
            rows={stores}
            columns={columndsWithWidth}
            checkboxSelection
            disableSelectionOnClick
            loading={false}
            components={{
                Toolbar: CustomToolbar,
                ColumnSortedAscendingIcon: ExpandLess,
                ColumnSortedDescendingIcon: ExpandMore
            }}
            sortingOrder={['asc', 'desc']}
            apiRef={apiRef}
        />
    );
};

export default ExportTable;
