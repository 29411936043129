import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Progress from "components/visuals/Progress";
import Error from "components/visuals/Error";
import Spacer from "components/Spacer";

import Search from "./Search";
import StoreCard from "./StoreCard";
import Title from "./Title";
import { Store } from "modules/customer/tools/product/store";
import { useAppDispatch, useAppSelector } from "store";
import { clearCandidateStore, selectCandidateStore, selectFilteredStores, setCandidateStore } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";

const NUMBER_OF_VISIBLE_STORES_INCREMENT = 15;

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const Stores: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const candidateStore = useAppSelector(selectCandidateStore);
    const [numberOfVisibleStores, setNumberOfVisibleStores] = React.useState<number>(0);
    const { isLoading, hasErrors, data: stores } = useAppSelector(selectFilteredStores);
    const storesLabel = `${stores.length} ${stores.length === 1 ? "store" : "stores"}`;

    const visibleStores = React.useMemo(() => {
        return stores.slice(0, numberOfVisibleStores);
    }, [stores, numberOfVisibleStores]);

    const handleSelect = (store: Store) => {
        dispatch(setCandidateStore(store));
    };

    const handleDeselect = () => {
        dispatch(clearCandidateStore());
    };

    const handleLoadMore = React.useCallback(() => {
        setNumberOfVisibleStores(previousNumberOfVisibleStores => previousNumberOfVisibleStores + NUMBER_OF_VISIBLE_STORES_INCREMENT);
    }, [setNumberOfVisibleStores]);

    React.useEffect(() => {
        setNumberOfVisibleStores(NUMBER_OF_VISIBLE_STORES_INCREMENT);
    }, [stores]);

    return (
        <InfiniteScroll
            initialLoad={false}
            loadMore={handleLoadMore}
            hasMore={numberOfVisibleStores <= stores.length}
            useWindow={false}
        >
            <Box marginBottom={4}>
                <Box position="sticky" top={0} bgcolor={theme.palette.background.paper} zIndex={theme.zIndex.drawer}>
                    <Title />
                    <Search />
                    <Box display="flex" alignItems="center" paddingTop={2} paddingBottom={0.5}>
                        <Typography variant="body1" component="div">
                            &nbsp;&nbsp;{storesLabel}
                        </Typography>
                        <Spacer />
                    </Box>
                </Box>
                {isLoading && (
                    <Progress />
                )}
                {hasErrors && (
                    <Error />
                )}
                {!isLoading && !hasErrors && visibleStores.length > 0 && (
                    <StyledCard elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                {visibleStores.map(store =>
                                    <Grid key={store.id} item xs={4}>
                                        <StoreCard
                                            store={store}
                                            selected={candidateStore?.id === store.id}
                                            onSelect={handleSelect}
                                            onDeselect={handleDeselect}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </StyledCard>
                )}
            </Box>
        </InfiniteScroll>
    );
};

export default Stores;
