import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import Search from "./Search";
import RangeCard from "./RangeCard";
import Title from "./Title";
import { useAppDispatch, useAppSelector } from "store";
import { clearCandidateRange, clearRangeSelection, loadRangeSelection, selectCandidateRange, selectCandidateStore, selectRanges, setCandidateRange } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import { Range } from "modules/customer/tools/product/storeRangeFilters/range";
import Progress from "components/visuals/Progress";
import Error from "components/visuals/Error";

const NUMBER_OF_VISIBLE_RANGES_INCREMENT = 15;

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.dark
    }
}))(Card);

const Ranges: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const candidateStore = useAppSelector(selectCandidateStore);

    React.useEffect(() => {
        dispatch(loadRangeSelection());
        return () => {
            dispatch(clearRangeSelection());
        };
    }, [dispatch, candidateStore]);

    const candidateRange = useAppSelector(selectCandidateRange);
    const [numberOfVisibleRanges, setNumberOfVisibleRanges] = React.useState<number>(0);
    const { isLoading, hasErrors, data: ranges } = useAppSelector(selectRanges);
    const rangesLabel = `${ranges.length} ${ranges.length === 1 ? "product range" : "product ranges"}`;

    const visibleRanges = React.useMemo(() => {
        return ranges.slice(0, numberOfVisibleRanges);
    }, [ranges, numberOfVisibleRanges]);

    const handleSelect = (range: Range) => {
        dispatch(setCandidateRange(range));
    };

    const handleDeselect = () => {
        dispatch(clearCandidateRange());
    };

    const handleLoadMore = React.useCallback(() => {
        setNumberOfVisibleRanges(previousNumberOfVisibleRanges => previousNumberOfVisibleRanges + NUMBER_OF_VISIBLE_RANGES_INCREMENT);
    }, [setNumberOfVisibleRanges]);

    React.useEffect(() => {
        setNumberOfVisibleRanges(NUMBER_OF_VISIBLE_RANGES_INCREMENT);
    }, [ranges]);

    return (
        <InfiniteScroll
            initialLoad={false}
            loadMore={handleLoadMore}
            hasMore={numberOfVisibleRanges <= ranges.length}
            useWindow={false}
        >
            <Box marginBottom={4}>
                <Box position="sticky" top={0} bgcolor={theme.palette.background.paper} zIndex={theme.zIndex.drawer}>
                    <Title />
                    <Search />
                    <Box display="flex" alignItems="center" paddingTop={2} paddingBottom={0.5}>
                        <Typography variant="body1" component="div">
                            &nbsp;&nbsp;{rangesLabel}
                        </Typography>
                        <Spacer />
                    </Box>
                </Box>
                {isLoading && (
                    <Progress />
                )}
                {hasErrors && (
                    <Error />
                )}
                {!isLoading && !hasErrors && visibleRanges.length > 0 && (
                    <StyledCard elevation={0}>
                        <CardContent>
                            <Grid container spacing={2}>
                                {visibleRanges.map(range =>
                                    <Grid key={range.id} item xs={4}>
                                        <RangeCard
                                            range={range}
                                            selected={candidateRange?.id === range.id}
                                            onSelect={handleSelect}
                                            onDeselect={handleDeselect}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </StyledCard>
                )}
            </Box>
        </InfiniteScroll>
    );
};

export default Ranges;
