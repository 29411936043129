import React from "react";
import { useAppSelector } from "store";

import DumbbellAlignment from "components/visuals/DumbellAlignment";
import { selectRetailCentreMetrics, selectCatchmentAlignmentIsLoading, selectCatchmentAlignmentHasErrors } from "modules/customer/tools/product/productStoreFit/productStoreFitSlice";
import { selectSelectedStore } from "modules/customer/tools/product/productSlice";

const CatchmentAlignmentToOptimalStore: React.FC = () => {
    const isLoading = useAppSelector(selectCatchmentAlignmentIsLoading);
    const hasErrors = useAppSelector(selectCatchmentAlignmentHasErrors);
    const selectedStore = useAppSelector(selectSelectedStore);
    const retailCentreMetrics = useAppSelector(selectRetailCentreMetrics);

    const chartValues = [{
        lowCategory: "Low affluence",
        highCategory: "High affluence",
        label: "Affluence",
        selectedValue: retailCentreMetrics?.affluenceCentile ?? 0,
        targetValue: 0
    }, {
        lowCategory: "Young",
        highCategory: "Old",
        label: "Age",
        selectedValue: retailCentreMetrics?.ageCentile ?? 0,
        targetValue: 0
    }, {
        lowCategory: "Very rural",
        highCategory: "Very urban",
        label: "Urbanicity",
        selectedValue: retailCentreMetrics?.urbanicityCentile ?? 0,
        targetValue: 0
    }, {
        lowCategory: "Less diverse",
        highCategory: "Very diverse",
        label: "Diversity",
        selectedValue: retailCentreMetrics?.diversityCentile ?? 0,
        targetValue: 0
    }, {
        lowCategory: "Adult-only households",
        highCategory: "Households with young children",
        label: "Children",
        selectedValue: retailCentreMetrics?.childrenCentile ?? 0,
        targetValue: 0
    }, {
        lowCategory: "Low area health",
        highCategory: "High area health",
        label: "Area health",
        selectedValue: retailCentreMetrics?.areaHealthCentile ?? 0,
        targetValue: 0
    }, {
        lowCategory: "Low footfall",
        highCategory: "High footfall",
        label: "Footfall",
        selectedValue: retailCentreMetrics?.footfallCentile ?? 0,
        targetValue: 0
    }];

    return (
        <DumbbellAlignment
            isLoading={isLoading}
            hasErrors={hasErrors}
            chartValues={chartValues}
            selectedSeriesName={`${selectedStore?.name} store`}
            targetSeriesName="Optimal store"
            targetIsSet={false}
            chartHeight={400}
            dataCy="catchment-alignment-dumbbell"
        />
    );
};

export default CatchmentAlignmentToOptimalStore;
