import React from "react";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import ProductInsight from "./productInsight/ProductInsight";
import Store from "./store/Store";
import { useAppDispatch, useAppSelector } from "store";
import { showStoreRangeFilters } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import { selectSelectedRange } from "modules/customer/tools/product/productSlice";

const ProductOpportunities: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedRange = useAppSelector(selectSelectedRange);

    React.useEffect(() => {
        if (!selectedRange) {
            dispatch(showStoreRangeFilters());
        }
    }, [dispatch, selectedRange]);

    return (
        <>
            {selectedRange && (
                <Chapter
                    title="Product opportunities"
                    subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                    dataCy="product-opportunities-chapter"
                >
                    <Subchapter
                        title="Product insight"
                        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        dataCy="product-insight-subchapter"
                    >
                        <ProductInsight />
                    </Subchapter>
                    <Subchapter
                        title="Store"
                        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        dataCy="store-subchapter"
                    >
                        <Store />
                    </Subchapter>
                </Chapter>
            )}
        </>
    );
};

export default ProductOpportunities;
