import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { Store } from "modules/customer/tools/product/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CoreRetailHub {
    public readonly localArea: string;
    public readonly latitude: number;
    public readonly longitude: number;

    constructor(
        localArea: string,
        latitude: number,
        longitude: number,
    ) {
        this.localArea = localArea;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export const loadCoreRetailHub = (store?: Store): AppThunk<Promise<CoreRetailHub | undefined>> => async (dispatch) => {
    try {
        if (!store) {
            return undefined;
        }

        const query = {
            dimensions: [
                "LocalAreaHotspot.MainOAID",
                "LocalAreaHotspot.HotSpotLat",
                "LocalAreaHotspot.HotSpotLong",
            ],
            filters: [{
                member: "LocalAreaHotspot.MainOAID",
                operator: "equals",
                values: [String(store.outputAreaCode)]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawResultSetData = resultSet.rawData();
        const coreRetailHub = rawResultSetData.length !== 0
            ? new CoreRetailHub(
                String(rawResultSetData[0]["LocalAreaHotspot.MainOAID"]),
                Number(rawResultSetData[0]["LocalAreaHotspot.HotSpotLat"]),
                Number(rawResultSetData[0]["LocalAreaHotspot.HotSpotLong"]))
            : undefined;

        return coreRetailHub;
    } catch (error) {
        dispatch(logError("Error loading Core Retail Hub.", error));
        throw error;
    }
};
