import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Mark, Menu, MenuItem, Slider, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";

import Spacer from "components/Spacer";

import { useAppDispatch, useAppSelector } from "store";
import {
    StoresFilter,
    clearStoresFilter,
    selectIsStoresFilterModified,
    selectCostTypes,
    selectStoreGroups,
    selectRegions,
    selectOpportunityValues,
    selectOpportunityValuePercentages,
    selectAverageSimilarityScore,
    selectCostAsPercentageOfRevenue,
    selectCostValues,
    selectStoresFilter,
    setStoresFilter
} from "modules/customer/insights/cost/filters/filtersSlice";

import numberFormatter from "utils/numberFormatter";

const StyledMenu = withStyles(theme => ({
    list: {
        width: theme.spacing(40),
        maxHeight: "70vh"
    }
}))(Menu);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    }
}))(TextField);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const costTypes = useAppSelector(selectCostTypes);
    const storeGroups = useAppSelector(selectStoreGroups);
    const regions = useAppSelector(selectRegions);
    const opportunityValues = useAppSelector(selectOpportunityValues);
    const opportunityValuePercentages = useAppSelector(selectOpportunityValuePercentages);
    const averageSimilarityScore = useAppSelector(selectAverageSimilarityScore);
    const costAsPercentageOfRevenue = useAppSelector(selectCostAsPercentageOfRevenue);
    const costValues = useAppSelector(selectCostValues);
    const storesFilter = useAppSelector(selectStoresFilter);
    const isStoresFilterModified = useAppSelector(selectIsStoresFilterModified);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [currentStoresFilter, setCurrentStoresFilter] = React.useState<StoresFilter>(storesFilter);

    // Opportunity value slider values
    const opportunityValuePercentileThresholds = opportunityValues.percentileThresholds;
    const opportunityValueSliderMinValue = opportunityValuePercentileThresholds.indexOf(Math.max(opportunityValues.minPercentileThreshold, currentStoresFilter.opportunityValue[0]));
    const opportunityValueSliderMaxValue = opportunityValuePercentileThresholds.indexOf(Math.min(opportunityValues.maxPercentileThreshold, currentStoresFilter.opportunityValue[1]));
    const opportunityValueSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < opportunityValuePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(opportunityValuePercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [opportunityValuePercentileThresholds]);

    // Opportunity value percentage slider values
    const opportunityValuePercentagesPercentileThresholds = opportunityValuePercentages.percentileThresholds;
    const opportunityValuePercentagesSliderMinValue = opportunityValuePercentagesPercentileThresholds.indexOf(Math.max(opportunityValuePercentages.minPercentileThreshold, currentStoresFilter.opportunityValuePercentage[0]));
    const opportunityValuePercentagesSliderMaxValue = opportunityValuePercentagesPercentileThresholds.indexOf(Math.min(opportunityValuePercentages.maxPercentileThreshold, currentStoresFilter.opportunityValuePercentage[1]));
    const opportunityValuePercentagesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < opportunityValuePercentagesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toPercentage(opportunityValuePercentagesPercentileThresholds[i], false, 2);
            marks.push({ value, label });
        }
        return marks;
    }, [opportunityValuePercentagesPercentileThresholds]);

    // Average similarity score slider values
    const averageSimilarityScorePercentileThresholds = averageSimilarityScore.percentileThresholds;
    const averageSimilarityScoreSliderMinValue = averageSimilarityScorePercentileThresholds.indexOf(Math.max(averageSimilarityScore.minPercentileThreshold, currentStoresFilter.averageSimilarityScore[0]));
    const averageSimilarityScoreSliderMaxValue = averageSimilarityScorePercentileThresholds.indexOf(Math.min(averageSimilarityScore.maxPercentileThreshold, currentStoresFilter.averageSimilarityScore[1]));
    const averageSimilarityScoreSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < averageSimilarityScorePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toPercentage(averageSimilarityScorePercentileThresholds[i], true, 0);
            marks.push({ value, label });
        }
        return marks;
    }, [averageSimilarityScorePercentileThresholds]);

    // Cost as percentage of revenue slider values
    const costAsPercentageOfRevenuePercentileThresholds = costAsPercentageOfRevenue.percentileThresholds;
    const costAsPercentageOfRevenueSliderMinValue = costAsPercentageOfRevenuePercentileThresholds.indexOf(Math.max(costAsPercentageOfRevenue.minPercentileThreshold, currentStoresFilter.costAsPercentageOfRevenue[0]));
    const costAsPercentageOfRevenueSliderMaxValue = costAsPercentageOfRevenuePercentileThresholds.indexOf(Math.min(costAsPercentageOfRevenue.maxPercentileThreshold, currentStoresFilter.costAsPercentageOfRevenue[1]));
    const costAsPercentageOfRevenueSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < costAsPercentageOfRevenuePercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toPercentage(costAsPercentageOfRevenuePercentileThresholds[i], false, 2);
            marks.push({ value, label });
        }
        return marks;
    }, [costAsPercentageOfRevenuePercentileThresholds]);

    // Cost value slider values
    const costValuesPercentileThresholds = costValues.percentileThresholds;
    const costValuesSliderMinValue = costValuesPercentileThresholds.indexOf(Math.max(costValues.minPercentileThreshold, currentStoresFilter.costValue[0]));
    const costValuesSliderMaxValue = costValuesPercentileThresholds.indexOf(Math.min(costValues.maxPercentileThreshold, currentStoresFilter.costValue[1]));
    const costValuesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < costValuesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(costValuesPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [costValuesPercentileThresholds]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearStoresFilter());
    };

    const setStoresFilterDelayed = React.useMemo(() => {
        return _.debounce((storesFilter: StoresFilter) => dispatch(setStoresFilter(storesFilter)), 400);
    }, [dispatch]);

    const handleCostTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const costType = event.target.value;
        const newStoresFilter = { ...storesFilter, costType };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleStoreGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const storeGroup = event.target.value;
        const newStoresFilter = { ...storesFilter, storeGroup };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const region = event.target.value;
        const newStoresFilter = { ...storesFilter, region };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleOpportunityValueChange = (event: React.ChangeEvent<{}>, value: any) => {
        const opportunityValue = [opportunityValuePercentileThresholds[value[0]], opportunityValuePercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, opportunityValue };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleOpportunityValueAsPercentageChange = (event: React.ChangeEvent<{}>, value: any) => {
        const opportunityValuePercentage = [opportunityValuePercentagesPercentileThresholds[value[0]], opportunityValuePercentagesPercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, opportunityValuePercentage };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleAverageSimilarityChange = (event: React.ChangeEvent<{}>, value: any) => {
        const averageSimilarityScore = [averageSimilarityScorePercentileThresholds[value[0]], averageSimilarityScorePercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, averageSimilarityScore };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleCostAsPercentageOfRevenueChange = (event: React.ChangeEvent<{}>, value: any) => {
        const costAsPercentageOfRevenue = [costAsPercentageOfRevenuePercentileThresholds[value[0]], costAsPercentageOfRevenuePercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, costAsPercentageOfRevenue };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    const handleCostValueChange = (event: React.ChangeEvent<{}>, value: any) => {
        const costValue = [costValuesPercentileThresholds[value[0]], costValuesPercentileThresholds[value[1]]];
        const newStoresFilter = { ...storesFilter, costValue };
        setCurrentStoresFilter(newStoresFilter);
        setStoresFilterDelayed(newStoresFilter);
    };

    React.useEffect(() => {
        setCurrentStoresFilter(storesFilter);
    }, [storesFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isStoresFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Cost type
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.costType}
                            onChange={handleCostTypeChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select cost type
                            </MenuItem>
                            {costTypes.map((costType, index) =>
                                <MenuItem key={index} value={costType}>
                                    {costType}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Store group
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.storeGroup}
                            onChange={handleStoreGroupChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select store group
                            </MenuItem>
                            {storeGroups.map((group, index) =>
                                <MenuItem key={index} value={group}>
                                    {group}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Region
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={currentStoresFilter.region}
                            onChange={handleRegionChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select region
                            </MenuItem>
                            {regions.map((region, index) =>
                                <MenuItem key={index} value={region}>
                                    {region}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Opportunity value (&pound;)</Typography>
                        <Slider
                            value={[opportunityValueSliderMinValue, opportunityValueSliderMaxValue]}
                            onChange={handleOpportunityValueChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={opportunityValueSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Opportunity value (%)</Typography>
                        <Slider
                            value={[opportunityValuePercentagesSliderMinValue, opportunityValuePercentagesSliderMaxValue]}
                            onChange={handleOpportunityValueAsPercentageChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={opportunityValuePercentagesSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Average similarity score (%)</Typography>
                        <Slider
                            value={[averageSimilarityScoreSliderMinValue, averageSimilarityScoreSliderMaxValue]}
                            onChange={handleAverageSimilarityChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={averageSimilarityScoreSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Cost as % of revenue</Typography>
                        <Slider
                            value={[costAsPercentageOfRevenueSliderMinValue, costAsPercentageOfRevenueSliderMaxValue]}
                            onChange={handleCostAsPercentageOfRevenueChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={costAsPercentageOfRevenueSliderMarks}
                        />
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div">Cost value</Typography>
                        <Slider
                            value={[costValuesSliderMinValue, costValuesSliderMaxValue]}
                            onChange={handleCostValueChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={costValuesSliderMarks}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
