import { Query, ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class RetailCentreMetrics {
    public readonly retailCentreID: number;
    public readonly storeCategoryID: number;
    public readonly affluenceCentile: number;
    public readonly ageCentile: number;
    public readonly childrenCentile: number;
    public readonly diversityCentile: number;
    public readonly urbanicityCentile: number;
    public readonly areaHealthCentile: number;
    public readonly footfallCentile: number;

    constructor(
        retailCentreID: number,
        storeCategoryID: number,
        affluenceCentile: number,
        ageCentile: number,
        childrenCentile: number,
        diversityCentile: number,
        urbanicityCentile: number,
        areaHealthCentile: number,
        footfallCentile: number
    ) {
        this.retailCentreID = retailCentreID;
        this.storeCategoryID = storeCategoryID;
        this.affluenceCentile = affluenceCentile;
        this.ageCentile = ageCentile;
        this.childrenCentile = childrenCentile;
        this.diversityCentile = diversityCentile;
        this.urbanicityCentile = urbanicityCentile;
        this.areaHealthCentile = areaHealthCentile;
        this.footfallCentile = footfallCentile;
    }
}

export const loadRetailCentreMetrics = (accountID: string, retailCentreID?: number, storeCategoryID?: number): AppThunk<Promise<RetailCentreMetrics>> => async (dispatch) => {
    try {
        const query: Query = {
            dimensions: [
                "LocationBenchmarkMetrics.RetailCentreID",
                "LocationBenchmarkMetrics.AffluenceCentile",
                "LocationBenchmarkMetrics.AgeCentile",
                "LocationBenchmarkMetrics.ChildrenCentile",
                "LocationBenchmarkMetrics.DiversityCentile",
                "LocationBenchmarkMetrics.UrbanicityCentile",
                "LocationBenchmarkMetrics.AreaHealthCentile",
                "LocationBenchmarkMetrics.FootfallCentile"
            ],
            filters: [{
                member: "LocationBenchmarkMetrics.Client_ID",
                operator: "equals",
                values: [accountID]
            }, {
                member: "LocationBenchmarkMetrics.StoreCategory_ID",
                operator: "equals",
                values: [String(storeCategoryID)]
            }, {
                member: "LocationBenchmarkMetrics.RetailCentreID",
                operator: "equals",
                values: [String(retailCentreID)]
            },],
            segments: [
                "LocationBenchmarkMetrics.Baseline",
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawRetailCentreMetrics = resultSet.rawData()[0];
        return new RetailCentreMetrics(
            retailCentreID ?? 0,
            storeCategoryID ?? 0,
            Number(rawRetailCentreMetrics["LocationBenchmarkMetrics.AffluenceCentile"] ?? 0),
            Number(rawRetailCentreMetrics["LocationBenchmarkMetrics.AgeCentile"] ?? 0),
            Number(rawRetailCentreMetrics["LocationBenchmarkMetrics.ChildrenCentile"] ?? 0),
            Number(rawRetailCentreMetrics["LocationBenchmarkMetrics.DiversityCentile"] ?? 0),
            Number(rawRetailCentreMetrics["LocationBenchmarkMetrics.UrbanicityCentile"] ?? 0),
            Number(rawRetailCentreMetrics["LocationBenchmarkMetrics.AreaHealthCentile"] ?? 0),
            Number(rawRetailCentreMetrics["LocationBenchmarkMetrics.FootfallCentile"] ?? 0)
        );
    } catch (error) {
        dispatch(logError("Error loading RetailCentreMetrics.", error));
        throw error;
    }
};
