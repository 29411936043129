import React from "react";
import Highcharts from "highcharts";

import { Box, Typography } from "@material-ui/core";
import Scatter from "components/visuals/Scatter";

import { useAppSelector } from "store";
import {
    selectCostCorrelationsCostType,
    selectCostCorrelationsMetric,
    selectCostCorrelations,
    CostMeasureType,
    selectCostCorrelationsCostMeasureType
} from "modules/customer/insights/cost/costDrivers/costDriversSlice";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

const formatMetricName = (metricName: string): string => {
    if (metricName === "Sqft") {
        return "Store size";
    }
    else if (metricName === "NumberOfCarParkSpace") {
        return "Car Park";
    }
    else if (metricName === "storeAgeDays") {
        return "Store age";
    }
    else if (metricName === "competitorSqft") {
        return "Competitor store size";
    }
    else if (metricName === "footfallDensityLTM") {
        return "Footfall";
    }

    return metricName;
};

const CostCorrelationsScatterChart: React.FC = () => {
    const costType = useAppSelector(selectCostCorrelationsCostType);
    const metric = useAppSelector(selectCostCorrelationsMetric);
    const costMeasureType = useAppSelector(selectCostCorrelationsCostMeasureType);
    const { data, isLoading, hasErrors } = useAppSelector(selectCostCorrelations);
    const xAxisTitle = costMeasureType === CostMeasureType.CostValue
        ? `${costType?.name ?? ""} costs`
        : `${costType?.name ?? ""} costs as a % of revenue`;

    const options: Highcharts.Options = {
        chart: {
            height: 450
        },
        xAxis: {
            title: {
                text: xAxisTitle
            },
            labels: {
                enabled: true,
                // @ts-ignore
                formatter: function () {
                    // @ts-ignore
                    return costMeasureType === CostMeasureType.CostValue
                        ? numberFormatter.toGBP(this.value, 1)
                        : numberFormatter.toPercentage(this.value, false, 2);
                }
            },
        },
        yAxis: {
            title: {
                text: formatMetricName(metric?.name ?? "")
            },
            labels: {
                enabled: true
            },
            min: 0,
            max: 10
        },
        series: [{
            type: "scatter",
            label: { enabled: false },
            dataLabels: { enabled: false },
            data: data.map(item => ({
                name: item.storeName,
                x: costMeasureType === CostMeasureType.CostValue ? item.costValue : item.costAsPercentageOfRevenue,
                y: item.correlationMetricValue,
                custom: {
                    costValue: item.costValue,
                    costAsPercentageOfRevenue: item.costAsPercentageOfRevenue
                }
            }))
        }],
        legend: { enabled: false },
        tooltip: {
            useHTML: true,
            headerFormat: '<table>',
            // @ts-ignore
            pointFormatter: function () {
                return stringUtils.tooltipHTML([
                    costMeasureType === CostMeasureType.CostValue ? "Cost" : "Cost as % of revenue",
                    costMeasureType === CostMeasureType.CostValue ? "Cost as % of revenue" : "Cost",
                    metric?.name
                ], {
                    header: this.name,
                    values: [
                        // @ts-ignore
                        costMeasureType === CostMeasureType.CostValue ? numberFormatter.toGBP(this.custom.costValue, 1) : numberFormatter.toPercentage(this.custom.costAsPercentageOfRevenue, false, 2),
                        // @ts-ignore
                        costMeasureType === CostMeasureType.CostValue ? numberFormatter.toPercentage(this.custom.costAsPercentageOfRevenue, false, 2) : numberFormatter.toGBP(this.custom.costValue, 1),
                        `${numberFormatter.toDecimalPlaces(this.y, 1)}/10`
                    ]
                });
            },
            footerFormat: '</table>'
        }
    };

    return (
        <Box data-cy="total-trend-cost-over-time">
            <Typography variant="h6" component="div" gutterBottom>
                {costType?.name} by {formatMetricName(metric?.name ?? "")}
            </Typography>
            <Scatter loading={isLoading} error={hasErrors} options={options} dataCy="correlations-scatter-chart" />
        </Box>
    );
};

export default CostCorrelationsScatterChart;
