import { AppThunk } from "appThunk";
import { Store } from "modules/customer/tools/product/store";
import { ExtendedResultSet, cubeLoadExtended } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CatchmentCustomerProfile {
    public readonly outputAreaCode: string;
    public readonly supergroupCode: number;
    public readonly supergroupName: string;
    public readonly groupName: string;
    public readonly subgroupName: string;
    public readonly probability: number;
    public readonly population: number;
    public readonly numberOfVisitors: number;

    constructor(
        outputAreaCode: string,
        supergroupCode: number,
        supergroupName: string,
        groupName: string,
        subgroupName: string,
        probability: number,
        population: number,
        numberOfVisitors: number,
    ) {
        this.outputAreaCode = outputAreaCode;
        this.supergroupCode = supergroupCode;
        this.supergroupName = supergroupName;
        this.groupName = groupName;
        this.subgroupName = subgroupName;
        this.probability = probability;
        this.population = population;
        this.numberOfVisitors = numberOfVisitors;
    }
}

interface CustomerProfilesDimensions {
    "CatchmentCustomerProfiles.OAID": string,
    "CatchmentCustomerProfiles.SupergroupCode": number,
    "CatchmentCustomerProfiles.SupergroupName": string,
    "CatchmentCustomerProfiles.GroupName": string,
    "CatchmentCustomerProfiles.SubgroupName": string,
    "CatchmentCustomerProfiles.Probability": number,
    "CatchmentCustomerProfiles.Distance": number,
    "CatchmentCustomerProfiles.Population": number,
    "CatchmentCustomerProfiles.NumberOfVisitors": number
}
export const loadCatchmentCustomerProfiles = (accountID: string, selectedStore?: Store): AppThunk<Promise<CatchmentCustomerProfile[]>> => async (dispatch) => {
    try {
        if (!selectedStore) {
            return [];
        }

        const query = {
            dimensions: [
                "CatchmentCustomerProfiles.OAID",
                "CatchmentCustomerProfiles.SupergroupCode",
                "CatchmentCustomerProfiles.SupergroupName",
                "CatchmentCustomerProfiles.GroupName",
                "CatchmentCustomerProfiles.SubgroupName",
                "CatchmentCustomerProfiles.Probability",
                "CatchmentCustomerProfiles.Population",
                "CatchmentCustomerProfiles.NumberOfVisitors"
            ],
            filters: [{
                member: "CatchmentCustomerProfiles.Client_ID",
                operator: "equals",
                values: [String(accountID)]
            }, {
                member: "CatchmentCustomerProfiles.RetailCentreID",
                operator: "equals",
                values: [String(selectedStore.retailCentreID)]
            }, {
                member: "CatchmentCustomerProfiles.StoreCategory_ID",
                operator: "equals",
                values: [String(selectedStore.storeCategoryID)]
            }],
            segments: [
                "CatchmentCustomerProfiles.Baseline"
            ],
            order: [
                ["CatchmentCustomerProfiles.OAID", "asc"]
            ]
        };

        const resultSet = await dispatch(cubeLoadExtended(query)) as unknown as ExtendedResultSet<CustomerProfilesDimensions>;
        const queryResponse = resultSet.loadResponses[0];
        return queryResponse.data.map(row => new CatchmentCustomerProfile(
            row["CatchmentCustomerProfiles.OAID"] ?? "",
            Number(row["CatchmentCustomerProfiles.SupergroupCode"] ?? 0),
            row["CatchmentCustomerProfiles.SupergroupName"] ?? "",
            row["CatchmentCustomerProfiles.GroupName"] ?? "",
            row["CatchmentCustomerProfiles.SubgroupName"] ?? "",
            Number(row["CatchmentCustomerProfiles.Probability"] ?? 0),
            Number(row["CatchmentCustomerProfiles.Population"] ?? 0),
            Number(row["CatchmentCustomerProfiles.NumberOfVisitors"] ?? 0),
        ));
    } catch (error) {
        dispatch(logError("Error loading CatchmentCustomerProfiles.", error));
        throw error;
    }
};
