import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

import { clearCostOverview, loadCostOverview } from "./costOverview/costOverviewSlice";
import { DateTime } from "luxon";
import { loadReferenceDate } from "./referenceDate";
import { loadStores, Store } from "./store";
import { CostsByStore, loadCostsByStores } from "./costsByStore";
import { median } from "mathjs";
import mathUtils from "utils/mathUtils";
import { numberSortExpression, SortDirection } from "utils/sortUtils";
import { DataWrapper } from "domain/dataWrapper";
import { CostReductionOpportunityByStore } from "./costReductionOpportunityByStore";
import { CostType, loadCostTypes } from "./costType";
import { StoreGroup, loadStoreGroups } from "./storeGroups";
import { Region, loadRegions } from "./regions";
import { loadCostDrivers } from "./costDrivers/costDriversSlice";
import { clearFilters } from "./filters/filtersSlice";
import { SimilarityMetric, loadSimilarityMetrics } from "./similarityMetric";
import { SimilarityMetricValue, loadSimilarityMetricValues } from "./similarityMetricValues";
import { StoreGroupCorrelations, loadStoreGroupCorrelations } from "./storeGroupCorrelations";

export enum CostChapter {
    CostOverview = 1,
    CostDrivers,
    StoreCosts
}

interface LoadCostResponse {
    referenceDate: DateTime,
    costTypes: CostType[],
    storeGroups: StoreGroup[],
    regions: Region[],
    similarityMetrics: SimilarityMetric[],
    similarityMetricValues: SimilarityMetricValue[],
    storeGroupCorrelations: StoreGroupCorrelations[]
}

interface CostState {
    isLoading: boolean,
    hasErrors: boolean,
    currentChapter: CostChapter,
    referenceDate: DateTime,
    costTypes: CostType[],
    storeGroups: StoreGroup[],
    regions: Region[],
    similarityMetrics: SimilarityMetric[],
    similarityMetricValues: SimilarityMetricValue[],
    storeGroupCorrelations: StoreGroupCorrelations[],
    stores: DataWrapper<Store[]>,
    costsByStores: DataWrapper<CostsByStore[]>,
    selectedStoreByCostType?: CostReductionOpportunityByStore
}

const initialState: CostState = {
    isLoading: false,
    hasErrors: false,
    currentChapter: CostChapter.CostOverview,
    referenceDate: DateTime.fromMillis(0, { zone: "utc" }),
    costTypes: [],
    storeGroups: [],
    regions: [],
    similarityMetrics: [],
    similarityMetricValues: [],
    storeGroupCorrelations: [],
    stores: { isLoading: false, hasErrors: false, data: [] },
    costsByStores: { isLoading: false, hasErrors: false, data: [] },
    selectedStoreByCostType: undefined
};

const costSlice = createSlice({
    name: "customer/inisghts/cost",
    initialState,
    reducers: {
        setCurrentChapter: (state, action: PayloadAction<CostChapter>) => {
            state.currentChapter = action.payload;
        },
        resetCurrentChapter: (state) => {
            state.currentChapter = initialState.currentChapter;
        },
        clearReferenceDate: (state) => {
            state.referenceDate = initialState.referenceDate;
        },
        clearCostTypes: (state) => {
            state.costTypes = initialState.costTypes;
        },
        clearStoreGroups: (state) => {
            state.storeGroups = initialState.storeGroups;
        },
        clearRegions: (state) => {
            state.regions = initialState.regions;
        },
        clearSimilarityMetrics: (state) => {
            state.similarityMetrics = initialState.similarityMetrics;
        },
        clearSimilarityMetricValues: (state) => {
            state.similarityMetricValues = initialState.similarityMetricValues;
        },
        clearStoreGroupCorrelations: (state) => {
            state.storeGroupCorrelations = initialState.storeGroupCorrelations;
        },
        chooseCandidateStoreByCostType: (state, action: PayloadAction<CostReductionOpportunityByStore>) => {
            state.selectedStoreByCostType = action.payload;
        },
        clearSelectedStoreByCostType: (state) => {
            state.selectedStoreByCostType = initialState.selectedStoreByCostType;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCostSetup.pending, (state: CostState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.referenceDate = initialState.referenceDate;
            state.costTypes = initialState.costTypes;
            state.storeGroups = initialState.storeGroups;
            state.regions = initialState.regions;
            state.similarityMetrics = initialState.similarityMetrics;
            state.similarityMetricValues = initialState.similarityMetricValues;
            state.storeGroupCorrelations = initialState.storeGroupCorrelations;
        });
        builder.addCase(loadCostSetup.rejected, (state: CostState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.referenceDate = initialState.referenceDate;
            state.costTypes = initialState.costTypes;
            state.storeGroups = initialState.storeGroups;
            state.regions = initialState.regions;
            state.similarityMetrics = initialState.similarityMetrics;
            state.similarityMetricValues = initialState.similarityMetricValues;
            state.storeGroupCorrelations = initialState.storeGroupCorrelations;
        });
        builder.addCase(loadCostSetup.fulfilled, (state: CostState, action: PayloadAction<LoadCostResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.referenceDate = action.payload.referenceDate;
            state.costTypes = action.payload.costTypes;
            state.storeGroups = action.payload.storeGroups;
            state.regions = action.payload.regions;
            state.similarityMetrics = action.payload.similarityMetrics;
            state.similarityMetricValues = action.payload.similarityMetricValues;
            state.storeGroupCorrelations = action.payload.storeGroupCorrelations;
        });
        builder.addCase(loadStores.pending, (state: CostState) => {
            state.stores.isLoading = true;
            state.stores.hasErrors = false;
            state.stores.data = initialState.stores.data;
        });
        builder.addCase(loadStores.rejected, (state: CostState) => {
            state.stores.isLoading = false;
            state.stores.hasErrors = true;
            state.stores.data = initialState.stores.data;
        });
        builder.addCase(loadStores.fulfilled, (state: CostState, action: PayloadAction<Store[]>) => {
            state.stores.isLoading = false;
            state.stores.hasErrors = false;
            state.stores.data = action.payload;
        });
        builder.addCase(loadCostsByStores.pending, (state: CostState) => {
            state.costsByStores.isLoading = true;
            state.costsByStores.hasErrors = false;
            state.costsByStores.data = initialState.costsByStores.data;
        });
        builder.addCase(loadCostsByStores.rejected, (state: CostState) => {
            state.costsByStores.isLoading = false;
            state.costsByStores.hasErrors = true;
            state.costsByStores.data = initialState.costsByStores.data;
        });
        builder.addCase(loadCostsByStores.fulfilled, (state: CostState, action: PayloadAction<CostsByStore[]>) => {
            state.costsByStores.isLoading = false;
            state.costsByStores.hasErrors = false;
            state.costsByStores.data = action.payload;
        });
    }
});

export const {
    setCurrentChapter,
    chooseCandidateStoreByCostType
} = costSlice.actions;

export const loadCostSetup = createAppAsyncThunk(
    "customer/insights/cost/loadCostSetup",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());

            const referenceDatePromise = thunkAPI.dispatch(loadReferenceDate());
            const costTypesPromise = thunkAPI.dispatch(loadCostTypes());
            const storeGroupsPromise = thunkAPI.dispatch(loadStoreGroups());
            const regionsPromise = thunkAPI.dispatch(loadRegions());
            const similarityMetricsPromise = thunkAPI.dispatch(loadSimilarityMetrics());
            const similarityMetricValuesPromise = thunkAPI.dispatch(loadSimilarityMetricValues());
            const storeGroupCorrelationsPromise = thunkAPI.dispatch(loadStoreGroupCorrelations());
            const setupResults = await Promise.all([
                referenceDatePromise,
                costTypesPromise,
                storeGroupsPromise,
                regionsPromise,
                similarityMetricsPromise,
                similarityMetricValuesPromise,
                storeGroupCorrelationsPromise
            ]);

            const referenceDate = setupResults[0];
            const costTypes = setupResults[1];
            const storeGroups = setupResults[2];
            const regions = setupResults[3];
            const similarityMetrics = setupResults[4];
            const similarityMetricValues = setupResults[5];
            const storeGroupCorrelations = setupResults[6];

            const loadCostResponse: LoadCostResponse = {
                referenceDate,
                costTypes,
                storeGroups,
                regions,
                similarityMetrics,
                similarityMetricValues,
                storeGroupCorrelations
            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Cost."));
            return thunkAPI.rejectWithValue(null);
        }
        finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearCost = (): AppThunk => async (dispatch) => {
    dispatch(costSlice.actions.resetCurrentChapter());
    dispatch(costSlice.actions.clearReferenceDate());
    dispatch(costSlice.actions.clearCostTypes());
    dispatch(costSlice.actions.clearStoreGroups());
    dispatch(costSlice.actions.clearRegions());
    dispatch(costSlice.actions.clearSimilarityMetrics());
    dispatch(costSlice.actions.clearSimilarityMetricValues());
    dispatch(costSlice.actions.clearStoreGroupCorrelations());
    dispatch(costSlice.actions.clearSelectedStoreByCostType());
    dispatch(clearInsights());
    dispatch(clearFilters());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        await dispatch(loadCostSetup());

        const state = getState();
        const referenceDate = selectReferenceDate(state);

        dispatch(loadStores({ referenceDate }));
        dispatch(loadCostsByStores({ referenceDate }));
        dispatch(loadCostOverview());
        dispatch(loadCostDrivers());
    }
    catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    dispatch(clearCostOverview());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.root.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.root.hasErrors;
};

export const selectCurrentChapter = (state: RootState) => {
    return state.customer.insights.cost.root.currentChapter;
};

export const selectReferenceDate = (state: RootState) => {
    return state.customer.insights.cost.root.referenceDate;
};

export const selectCostTypes = (state: RootState) => {
    return state.customer.insights.cost.root.costTypes;
};

export const selectStoreGroups = (state: RootState) => {
    return state.customer.insights.cost.root.storeGroups;
};

export const selectRegions = (state: RootState) => {
    return state.customer.insights.cost.root.regions;
};

export const selectSimilarityMetrics = (state: RootState) => {
    return state.customer.insights.cost.root.similarityMetrics;
};

export const selectSimilarityMetricValues = (state: RootState) => {
    return state.customer.insights.cost.root.similarityMetricValues;
};

export const selectStoreGroupCorrelations = (state: RootState) => {
    return state.customer.insights.cost.root.storeGroupCorrelations;
};

export const selectStores = (state: RootState) => {
    return state.customer.insights.cost.root.stores;
};

export const selectCostsByStores = (state: RootState) => {
    return state.customer.insights.cost.root.costsByStores;
};

export const selectSelectedStoreByCostType = (state: RootState) => {
    return state.customer.insights.cost.root.selectedStoreByCostType;
};

export const selectCostReductionOpportunityByStore = createSelector(
    selectStores,
    selectCostsByStores,
    (stores, costsByStore) => {
        const costReductionOpportunityByStore: DataWrapper<CostReductionOpportunityByStore[]> = {
            isLoading: stores.isLoading || costsByStore.isLoading,
            hasErrors: stores.hasErrors || costsByStore.hasErrors,
            data: []
        };

        if (costReductionOpportunityByStore.isLoading || costReductionOpportunityByStore.hasErrors) {
            return costReductionOpportunityByStore;
        }

        const costsWithStores = costsByStore.data.map(costByStore => {
            const store = stores.data.find(store => store.id === costByStore.storeId);
            return {
                ...costByStore,
                ...store,
                id: costByStore.id
            };
        });

        const costsWithStoresAndOpportunity: CostReductionOpportunityByStore[] = costsWithStores.map(currentStore => {
            const currentCostType = currentStore.costName;
            const similarStoreIds = currentStore.similarStores?.map(similarStore => similarStore.id) ?? [];
            const similarStores = costsWithStores.filter(store => similarStoreIds.includes(store.storeId) && currentCostType === store.costName);
            const similarStoresAverageStoreCostValue = similarStores.length === 0 ? 0 :
                median(similarStores.map(similarStore => similarStore.costValue));
            const similarStoresAverageCostPercentageOfRevenue = similarStores.length === 0 ? 0 :
                median(similarStores.map(similarStore => mathUtils.safePercentage(similarStore.costValue, (similarStore?.revenue ?? 0)) / 100));

            const averageSimilarityScore = similarStores.length === 0 ? 0 :
                median(currentStore.similarStores?.map(similarStore => similarStore.similarityScore) ?? []);

            const currentStoreCostAsPercentage = mathUtils.safePercentage(currentStore.costValue, (currentStore?.revenue ?? 0)) / 100;
            const opportunityValueAsPercentageOfRevenue = currentStoreCostAsPercentage - similarStoresAverageCostPercentageOfRevenue;
            const opportunityValue = opportunityValueAsPercentageOfRevenue * (currentStore?.revenue ?? 0);

            return {
                ...currentStore,
                storeId: currentStore.storeId,
                storeName: currentStore.name,
                openingDate: currentStore.openingDate,
                retailCentreId: currentStore.retailCentreId,
                averageSimilarityScore: averageSimilarityScore,
                opportunityValue: opportunityValue,
                opportunityValueAsPercentageOfRevenue: opportunityValueAsPercentageOfRevenue,
                costAsPercentageOfRevenue: currentStoreCostAsPercentage,
                similarStoresAverageCostValue: similarStoresAverageStoreCostValue,
                similarStoresAverageCostPercentageOfRevenue: similarStoresAverageCostPercentageOfRevenue
            };
        }
        );

        costReductionOpportunityByStore.data = costsWithStoresAndOpportunity.sort((a, b) => numberSortExpression(a.opportunityValue, b.opportunityValue, SortDirection.DESC));
        return costReductionOpportunityByStore;
    }
);

export default costSlice;
