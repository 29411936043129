import React from "react";

import { Button, withStyles } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "store";
import { chooseStoreAndRange } from "modules/customer/tools/product/productSlice";
import { hideStoreRangeFilters, selectActiveStep, selectCandidateRange, selectCandidateStore, setActiveStep, StoreRangeFilterStep } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";

const StyledButton = withStyles(theme => ({
    root: {
        marginLeft: theme.spacing(-1.5)
    }
}))(Button);

const Actions: React.FC = () => {
    const dispatch = useAppDispatch();
    const activeStep = useAppSelector(selectActiveStep);
    const candidateStore = useAppSelector(selectCandidateStore);
    const candidateRange = useAppSelector(selectCandidateRange);

    const handleFinishClick = () => {
        dispatch(chooseStoreAndRange({ store: candidateStore, range: candidateRange }));
        dispatch(hideStoreRangeFilters());
    };

    const handleNextClick = () => {
        dispatch(setActiveStep(StoreRangeFilterStep.SelectRange));
    };

    const handleBackClick = () => {
        dispatch(setActiveStep(StoreRangeFilterStep.SelectStore));
    };

    return (
        <>
            {(activeStep === StoreRangeFilterStep.SelectStore) && (
                <StyledButton
                    variant="text"
                    size="medium"
                    color="default"
                    disableElevation
                    onClick={handleNextClick}
                    data-cy="btn-back"
                >
                    Next
                </StyledButton>
            )}
            {(activeStep === StoreRangeFilterStep.SelectRange) && (
                <StyledButton
                    variant="text"
                    size="medium"
                    color="default"
                    disableElevation
                    onClick={handleBackClick}
                    data-cy="btn-back"
                >
                    Back
                </StyledButton>
            )}
            <Button
                variant="contained"
                size="small"
                color="secondary"
                disableElevation
                onClick={handleFinishClick}
                data-cy="btn-finish"
            >
                Finish
            </Button>
        </>
    );
};

export default Actions;
