import React from "react";
import AverageDailyFootfallLevel from "./AverageDailyFootfallLevel";

const FootfallAlignment: React.FC = () => {
    return (
        <AverageDailyFootfallLevel />
    );
};

export default FootfallAlignment;
