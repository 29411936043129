import React from "react";

import { Grid } from "@material-ui/core";

import CatchmentAlignmentToOptimalStore from "./CatchmentAlignmentToOptimalStore";
import Map from "./Map";
import CustomerProfilesTreemap from "./CustomerProfilesTreemap";
import CustomerProfileDefinitions from "./CustomerProfileDefinitions";

const CatchmentAlignment: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CatchmentAlignmentToOptimalStore />
            </Grid>
            <Grid item xs={12}>
                <Map />
            </Grid>
            <Grid item xs={12}>
                <CustomerProfilesTreemap />
            </Grid>
            <Grid item xs={12}>
                <CustomerProfileDefinitions />
            </Grid>
        </Grid>
    );
};

export default CatchmentAlignment;
