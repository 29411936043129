import React from "react";

import { Box } from "@material-ui/core";

import KPIFact from "components/visuals/KPIFact";

import { selectNumberOfCompetitors } from "modules/customer/tools/product/productStoreFit/productStoreFitSlice";
import { selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { useAppSelector } from "store";

const NumberOfDirectCompetitors: React.FC = () => {
    const numberOfCompetitors = useAppSelector(selectNumberOfCompetitors);
    const isLoading = numberOfCompetitors.isLoading;
    const hasErrors = numberOfCompetitors.hasErrors;
    const value = numberOfCompetitors.data.direct;
    const valueFormatted = `${value}`;
    const store = useAppSelector(selectSelectedStore);
    const label = `Number of direct competitors within 5kms of your ${store?.name} store`;

    return (
        <Box width="100%" height="100%" data-cy="number-of-direct-competitors">
            <KPIFact loading={isLoading} error={hasErrors} label={label} value={valueFormatted} />
        </Box>
    );
};

export default NumberOfDirectCompetitors;
