import { combineReducers } from "redux";

import slice from "./productSlice";
import partnerFilters from "./partnerFilters";
import storeRangeFilters from "./storeRangeFilters";
import storeOpportunities from "./storeOpportunities/index";
import productStoreFit from "./productStoreFit/";

const reducer = combineReducers({
    root: slice.reducer,
    partnerFilters,
    storeRangeFilters,
    storeOpportunities,
    productStoreFit
});

export default reducer;
