import React from "react";
import Highcharts from "highcharts";

import { Box, useTheme } from "@material-ui/core";

import Waterfall from "components/visuals/Waterfall";

import { selectIsInsightExpanded } from "modules/customer/tools/location/locationSlice";
import {
    selectRevenuePredictionDrivers,
    selectHasErrors,
    selectIsLoading,
    selectPredictionIsLoading,
    selectPredictionHasErrors
} from "modules/customer/tools/location/overview/overviewSlice";
import { useAppSelector } from "store";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

const RevenuePredictionDrivers: React.FC = () => {
    const theme = useTheme();
    const revenuePredictionDriversData = useAppSelector(selectRevenuePredictionDrivers);
    const isInsightExpanded = useAppSelector(selectIsInsightExpanded);
    const overviewHasErrors = useAppSelector(selectHasErrors);
    const overviewIsLoading = useAppSelector(selectIsLoading);
    const predictionHasErrors = useAppSelector(selectPredictionHasErrors);
    const predictionIsLoading = useAppSelector(selectPredictionIsLoading);
    const isLoading = overviewIsLoading || predictionIsLoading;
    const hasErrors = overviewHasErrors || predictionHasErrors;
    const features = revenuePredictionDriversData.map(data => data.name);
    const descriptions = revenuePredictionDriversData.map(data => data.description);
    const shapValues = revenuePredictionDriversData.map(data => data.shapValue);
    const averageValues = revenuePredictionDriversData.map(data => data.averageValue);
    const actualValues = revenuePredictionDriversData.map(data => data.actualValue);
    const positivePrefixes = revenuePredictionDriversData.map(data => data.positivePrefix);
    const negativePrefixes = revenuePredictionDriversData.map(data => data.negativePrefix);
    const suffixes = revenuePredictionDriversData.map(data => data.suffix);

    let runningTotal = 0;
    let minShapValue = Number.MAX_VALUE;
    let maxShapValue = -Number.MAX_VALUE;
    for (let i = 0; i < shapValues.length - 1; i++) {
        runningTotal += shapValues[i];

        if (runningTotal < minShapValue) {
            minShapValue = runningTotal;
        }

        if (runningTotal > maxShapValue) {
            maxShapValue = runningTotal;
        }
    }
    const minMaxDifference = maxShapValue - minShapValue;
    const yAxisMin = Math.max(0, maxShapValue - 2 * minMaxDifference);

    const series = shapValues.map((shapValue, index) => {
        const color = (index === 0 || index === shapValues.length - 1) ? theme.palette.info.main :
            (shapValue < 0) ? theme.palette.error.main : theme.palette.success.main;
        return {
            y: shapValue,
            color,
            isSum: (index === shapValues.length - 1),
            custom: {
                description: descriptions[index],
                prefix: actualValues[index] >= averageValues[index] ? positivePrefixes[index] : negativePrefixes[index],
                suffix: suffixes[index]
            }
        };
    });

    const chartHeight = React.useRef<number>(750);
    const calledByRedraw = React.useRef<boolean>(false);

    const maintainChartHeight = React.useCallback((chart: Highcharts.Chart) => {
        if (!isInsightExpanded && !calledByRedraw.current) {
            chartHeight.current = chart.chartWidth * 1.5;
            chart.update({
                chart: {
                    height: chartHeight.current
                }
            }, false);
            calledByRedraw.current = true;
            chart.redraw();
            calledByRedraw.current = false;
        }
    }, [isInsightExpanded]);

    const options = React.useMemo((): Highcharts.Options => ({
        chart: {
            inverted: !isInsightExpanded,
            height: chartHeight.current,
            events: {
                load: function () {
                    maintainChartHeight(this);
                },
                redraw: function () {
                    maintainChartHeight(this);
                }
            }
        },
        title: {
            text: `Breakdown of revenue prediction drivers`
        },
        xAxis: {
            categories: features
        },
        yAxis: {
            min: yAxisMin,
            startOnTick: false,
            endOnTick: false
        },
        legend: {
            itemDistance: 6,
            enabled: true
        },
        tooltip: {
            useHTML: true,
            headerFormat: `<table>`,
            footerFormat: `</table>`,
            style: {
                width: 300
            },
            pointFormatter: function () {
                const impact = numberFormatter.toGBP(this.y, 1);
                const description = this.options.custom?.description;
                const nonAggregatedHeader = !this.options.custom?.prefix ? `` : `
                    ${this.options.custom?.prefix} ${this.category} ${this.options.custom?.suffix}.
                `;
                const impactHeader = [`Baseline`, `Pinned location`].includes(String(this.category)) ? `` : `Revenue impact`;
                const impactValue = [`Baseline`, `Pinned location`].includes(String(this.category)) ? `` : impact;
                const tooltipProps = {
                    header: String(this.category ?? ""),
                    values: ["", "", impactValue, ""],
                    valueFormatting: `color:${this.color}`,
                    categoryFormattingArr: ["", "font-weight: lighter", ""],
                    categorySpan: [true, true, false, true]
                };
                return (stringUtils.tooltipHTML(["", description, impactHeader, nonAggregatedHeader], tooltipProps));
            }
        },
        series: [{
            name: "Features",
            showInLegend: false,
            data: series,
            type: "waterfall",
        }, {
            grouping: false,
            name: "Revenue prediction",
            color: theme.palette.info.main,
            type: "column",
            data: [],
            events: {
                legendItemClick: function () {
                    return false;
                }
            }
        }, {
            grouping: false,
            name: "Positive impact",
            color: theme.palette.success.main,
            type: "column",
            data: [],
            events: {
                legendItemClick: function () {
                    return false;
                }
            }
        }, {
            grouping: false,
            name: "Negative impact",
            color: theme.palette.error.main,
            type: "column",
            data: [],
            events: {
                legendItemClick: function () {
                    return false;
                }
            }
        }],
        plotOptions: {
            waterfall: {
                dataLabels: {
                    enabled: false
                }
            }
        }
    }), [isInsightExpanded, series, theme, features, yAxisMin, maintainChartHeight]);

    return (
        <Box data-cy="revenue-prediction-drivers">
            <Waterfall loading={isLoading} error={hasErrors} options={options} />
        </Box>
    );
};

export default RevenuePredictionDrivers;
