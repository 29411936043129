import React from "react";
import Highcharts from "highcharts";

import { useTheme } from "@material-ui/core/styles";

import Bar from "components/visuals/Bar";
import useColourPalette from "components/visuals/useColourPalette";

import { ProductSales } from "modules/customer/tools/product/productSales";
import { useAppSelector } from "store";
import { selectUserInfo } from "modules/auth/authSlice";
import stringUtils from "utils/stringUtils";
import numberFormatter from "utils/numberFormatter";

interface BarChartProps {
    sales: ProductSales
}

const BarChart: React.FC<BarChartProps> = (props) => {
    const {
        sales
    } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();
    const clientName = useAppSelector(selectUserInfo).companyDisplayName;
    const categories = ["Optimised sales", "Estimated sales", `${clientName} sourced sales`];
    const values = [sales.optimisedSales, sales.estimatedSales, sales.clientSourcedSales];

    const options: Highcharts.Options = {
        chart: {
            height: theme.spacing(20),
            backgroundColor: 'transparent'
        },
        legend: {
            enabled: false
        },
        xAxis: {
            categories,
            labels: {
                enabled: true
            }
        },
        yAxis: {
            labels: {
                enabled: false
            },
            gridLineWidth: 0
        },
        plotOptions: {
            bar: {
                pointPadding: 0.01,
                groupPadding: 0.01
            }
        },
        tooltip: {
            enabled: true,
            outside: true,
            positioner: function (labelWidth, labelHeight, point) {
                const x = point.plotX + this.chart.plotLeft;
                const y = point.plotY + this.chart.plotTop - labelHeight;
                return { x: x, y: Math.max(y, 0) };
            },
            style: {
                zIndex: theme.zIndex.tooltip
            },
            headerFormat: ``,
            footerFormat: ``,
            // @ts-ignore
            formatter: function () {
                const header = sales.name;

                const categoriesArr = [...categories, "Headroom"];
                const categoriesFormatArr = categories.map((category, index) => {
                    return `color:${colourPalette.categorical[index]};font-weight:bold`;
                });
                categoriesFormatArr.push(`color:${theme.palette.text.primary};font-weight:bold`);
                const valuesArr = [...values, sales.salesHeadroom].map(value => numberFormatter.toGBP(value));

                return `<table>${stringUtils.tooltipHTML(
                    categoriesArr,
                    {
                        header: header,
                        values: valuesArr,
                        categoryFormattingArr: categoriesFormatArr,
                        valueFormatting: ""
                    }
                )}</table>`;
            },
            useHTML: true
        },
        series: [{
            data: values.map((value, index) => ({
                y: value,
                color: colourPalette.categorical[index]
            })),
            type: "bar",
            dataLabels: {
                enabled: false
            }
        }],
        exporting: {
            enabled: false
        }
    };

    return (
        <Bar loading={false} error={false} options={options} />
    );
};

export default BarChart;
