import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { RootState } from "store";

import { CostReductionOpportunityByStore } from "modules/customer/insights/cost/costReductionOpportunityByStore";
import { selectCostReductionOpportunityByStore } from "modules/customer/insights/cost/costSlice";

import mathUtils from "utils/mathUtils";
import { SortDirection, numberSortExpression, stringSortExpression } from "utils/sortUtils";

interface FiltersVisibility {
    isVisible: boolean
}

interface SliderThresholds {
    minPercentileThreshold: number,
    maxPercentileThreshold: number,
    percentileThresholds: number[]
}

export enum FilterStep {
    SelectStore
}

export interface StoreSearch {
    name: string
}

export interface StoresFilter {
    costType: string,
    storeGroup: string,
    region: string,
    opportunityValue: number[],
    opportunityValuePercentage: number[],
    averageSimilarityScore: number[],
    costAsPercentageOfRevenue: number[],
    costValue: number[]
}

export enum StoreSortField {
    OpportunityValue,
    OpportunityValuePercentage,
    AverageSimilarityScore,
    CostAsPercentageOfRevenue,
    CostValue,
    StoreName
}

interface StoresSort {
    field: StoreSortField,
    direction: SortDirection
}

interface FiltersState {
    filtersVisibility: FiltersVisibility,
    activeStep: FilterStep,
    candidateStore?: CostReductionOpportunityByStore,
    storeSearch: StoreSearch,
    storesFilter: StoresFilter,
    storesSort: StoresSort
}

const initialState: FiltersState = {
    filtersVisibility: {
        isVisible: false
    },
    activeStep: FilterStep.SelectStore,
    candidateStore: undefined,
    storeSearch: {
        name: ""
    },
    storesFilter: {
        costType: "",
        storeGroup: "",
        region: "",
        opportunityValue: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        opportunityValuePercentage: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        averageSimilarityScore: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        costAsPercentageOfRevenue: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        costValue: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
    },
    storesSort: {
        field: StoreSortField.OpportunityValue,
        direction: SortDirection.DESC
    },
};

const filtersSlice = createSlice({
    name: "customer/insights/portfolio/filters",
    initialState,
    reducers: {
        showFilters: (state) => {
            state.activeStep = FilterStep.SelectStore;
            state.filtersVisibility.isVisible = true;
        },
        hideFilters: (state) => {
            state.filtersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<FilterStep>) => {
            state.activeStep = action.payload;
        },
        clearActiveStep: (state) => {
            state.activeStep = initialState.activeStep;
        },
        setCandidateStore: (state, action: PayloadAction<CostReductionOpportunityByStore | undefined>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = initialState.candidateStore;
        },
        setStoreSearch: (state, action: PayloadAction<StoreSearch>) => {
            state.storeSearch = action.payload;
        },
        clearStoreSearch: (state) => {
            state.storeSearch = initialState.storeSearch;
        },
        setStoresFilter: (state, action: PayloadAction<StoresFilter>) => {
            state.storesFilter = action.payload;
        },
        clearStoresFilter: (state) => {
            state.storesFilter = initialState.storesFilter;
        },
        setStoresSort: (state, action: PayloadAction<StoresSort>) => {
            state.storesSort = action.payload;
        },
        clearStoresSort: (state) => {
            state.storesSort = initialState.storesSort;
        }
    }
});

export const {
    showFilters,
    hideFilters,
    setActiveStep,
    clearActiveStep,
    setCandidateStore,
    clearCandidateStore,
    setStoreSearch,
    clearStoreSearch,
    setStoresFilter,
    clearStoresFilter,
    setStoresSort,
    clearStoresSort
} = filtersSlice.actions;

export const clearFilters = (): AppThunk => async (dispatch) => {
    dispatch(filtersSlice.actions.hideFilters());
    dispatch(filtersSlice.actions.clearCandidateStore());
    dispatch(filtersSlice.actions.clearStoreSearch());
    dispatch(filtersSlice.actions.clearActiveStep());
    dispatch(filtersSlice.actions.clearStoresFilter());
    dispatch(filtersSlice.actions.clearStoresSort());
};

export const selectFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.insights.cost.filters.filtersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.insights.cost.filters.activeStep;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.insights.cost.filters.candidateStore;
};

export const selectStoreSearch = (state: RootState) => {
    return state.customer.insights.cost.filters.storeSearch;
};

export const selectStoresFilter = (state: RootState) => {
    return state.customer.insights.cost.filters.storesFilter;
};

export const selectStoresSort = (state: RootState) => {
    return state.customer.insights.cost.filters.storesSort;
};

export const selectCostTypes = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const costTypes = stores.data
            .map(store => store.costName)
            .sort((a, b) => stringSortExpression(a, b, SortDirection.ASC));
        return Array.from(new Set(costTypes));
    }
);

export const selectStoreGroups = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const storeGroups = stores.data
            .map(store => store.group ?? "Unknown")
            .sort((a, b) => stringSortExpression(a, b, SortDirection.ASC));
        return Array.from(new Set(storeGroups));
    }
);

export const selectRegions = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const regions = stores.data
            .map(store => store.region ?? "Unknown")
            .sort((a, b) => stringSortExpression(a, b, SortDirection.ASC));
        return Array.from(new Set(regions));
    }
);

const generateSliderThresholds = (values: number[]): SliderThresholds => {
    const percentileThresholds = mathUtils.percentileThresholds(values, 5);
    percentileThresholds.push(values[(values.length - 1)]);
    return {
        minPercentileThreshold: percentileThresholds[0],
        maxPercentileThreshold: percentileThresholds[percentileThresholds.length - 1],
        percentileThresholds
    };
};

export const selectOpportunityValues = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const opportunityValues = stores.data
            .map(store => store.opportunityValue)
            .sort((a, b) => a - b);
        return generateSliderThresholds(opportunityValues);
    }
);

export const selectOpportunityValuePercentages = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const opportunityValuePercentages = stores.data
            .map(store => store.opportunityValueAsPercentageOfRevenue)
            .sort((a, b) => a - b);
        return generateSliderThresholds(opportunityValuePercentages);
    }
);

export const selectAverageSimilarityScore = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const averageSimilarityScore = stores.data
            .map(store => store.averageSimilarityScore)
            .sort((a, b) => a - b);
        return generateSliderThresholds(averageSimilarityScore);
    }
);

export const selectCostAsPercentageOfRevenue = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const costAsPercentageOfRevenue = stores.data
            .map(store => store.costAsPercentageOfRevenue)
            .sort((a, b) => a - b);
        return generateSliderThresholds(costAsPercentageOfRevenue);
    }
);

export const selectCostValues = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (stores) => {
        const costValues = stores.data
            .map(store => store.costValue)
            .sort((a, b) => a - b);
        return generateSliderThresholds(costValues);
    }
);

export const selectIsStoresFilterModified = createSelector(
    selectStoresFilter,
    (storesFilter) => {
        return storesFilter.costType !== initialState.storesFilter.costType
            || storesFilter.storeGroup !== initialState.storesFilter.storeGroup
            || storesFilter.region !== initialState.storesFilter.region
            || storesFilter.opportunityValue[0] !== initialState.storesFilter.opportunityValue[0]
            || storesFilter.opportunityValue[1] !== initialState.storesFilter.opportunityValue[1]
            || storesFilter.opportunityValuePercentage[0] !== initialState.storesFilter.opportunityValuePercentage[0]
            || storesFilter.opportunityValuePercentage[1] !== initialState.storesFilter.opportunityValuePercentage[1]
            || storesFilter.averageSimilarityScore[0] !== initialState.storesFilter.averageSimilarityScore[0]
            || storesFilter.averageSimilarityScore[1] !== initialState.storesFilter.averageSimilarityScore[1]
            || storesFilter.costAsPercentageOfRevenue[0] !== initialState.storesFilter.costAsPercentageOfRevenue[0]
            || storesFilter.costAsPercentageOfRevenue[1] !== initialState.storesFilter.costAsPercentageOfRevenue[1]
            || storesFilter.costValue[0] !== initialState.storesFilter.costValue[0]
            || storesFilter.costValue[1] !== initialState.storesFilter.costValue[1];
    }
);

export const selectFilteredStores = createSelector(
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    selectStoreSearch,
    selectStoresFilter,
    selectStoresSort,
    (stores, search, filter, sort) => {
        const searchString = search.name.toLowerCase();
        const filteredStores = stores.data.filter(store =>
            (!searchString || store?.storeName?.toLowerCase().includes(searchString))
            && (!filter.costType || store.costName === filter.costType)
            && (!filter.storeGroup || store.group === filter.storeGroup)
            && (!filter.region || store.region === filter.region)
            && store.opportunityValue >= filter.opportunityValue[0]
            && store.opportunityValue <= filter.opportunityValue[1]
            && store.opportunityValueAsPercentageOfRevenue >= filter.opportunityValuePercentage[0]
            && store.opportunityValueAsPercentageOfRevenue <= filter.opportunityValuePercentage[1]
            && store.averageSimilarityScore >= filter.averageSimilarityScore[0]
            && store.averageSimilarityScore <= filter.averageSimilarityScore[1]
            && store.costAsPercentageOfRevenue >= filter.costAsPercentageOfRevenue[0]
            && store.costAsPercentageOfRevenue <= filter.costAsPercentageOfRevenue[1]
            && store.costValue >= filter.costValue[0]
            && store.costValue <= filter.costValue[1]
        );

        return filteredStores.sort((storeA, storeB) => {
            switch (sort.field) {
                case StoreSortField.OpportunityValuePercentage:
                    return numberSortExpression(storeA.opportunityValueAsPercentageOfRevenue, storeB.opportunityValueAsPercentageOfRevenue, sort.direction);
                case StoreSortField.AverageSimilarityScore:
                    return numberSortExpression(storeA.averageSimilarityScore, storeB.averageSimilarityScore, sort.direction);
                case StoreSortField.CostAsPercentageOfRevenue:
                    return numberSortExpression(storeA.costAsPercentageOfRevenue, storeB.costAsPercentageOfRevenue, sort.direction);
                case StoreSortField.CostValue:
                    return numberSortExpression(storeA.costValue, storeB.costValue, sort.direction);
                case StoreSortField.StoreName:
                    return stringSortExpression((storeA.storeName ?? ""), (storeB.storeName ?? ""), sort.direction);
                case StoreSortField.OpportunityValue:
                default:
                    return numberSortExpression(storeA.opportunityValue, storeB.opportunityValue, sort.direction);
            }
        });
    }
);

export default filtersSlice;
