import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class CustomerProfileDefinition {
    public readonly id: string;
    public readonly name: string;
    public readonly level: string;
    public readonly description: string;

    constructor(id: string, name: string, level: string, description: string) {
        this.id = id;
        this.name = name;
        this.level = level;
        this.description = description;
    }
}

export const loadCustomerProfileDefinitions = (): AppThunk<Promise<CustomerProfileDefinition[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "PenPortraits.PenPortraitID",
                "PenPortraits.PenPortraitName",
                "PenPortraits.PenPortraitLevel",
                "PenPortraits.PenPortraitDescription"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();
        return rawData.map(row => new CustomerProfileDefinition(
            row["PenPortraits.PenPortraitID"] ?? "",
            row["PenPortraits.PenPortraitName"] ?? "",
            row["PenPortraits.PenPortraitLevel"] ?? "",
            row["PenPortraits.PenPortraitDescription"] ?? ""
        ));

    } catch (error) {
        dispatch(logError("Error loading CustomerProfileDefinitions.", error));
        throw error;
    }
};
