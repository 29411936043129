import React from "react";
import ProductStoreSales from "./ProductStoreSales";

const ProductStoreFit: React.FC = () => {
    return (
        <ProductStoreSales />
    );
};

export default ProductStoreFit;
