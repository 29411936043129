import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectSelectedRange, selectSelectedStore, selectStoresTotalSales } from "modules/customer/tools/product/productSlice";

import KPIComparison from "components/visuals/KPIComparison";
import numberFormatter from "utils/numberFormatter";

const ProductStoreSales = () => {
    const selectedRange = useAppSelector(selectSelectedRange);
    const selectedStore = useAppSelector(selectSelectedStore);
    const { isLoading, hasErrors, data } = useAppSelector(selectStoresTotalSales);
    const label = `Total headroom value ${selectedRange?.name} - ${selectedStore?.name}`;

    return (
        <Box width="100%" height="100%" data-cy="product-store-sales">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(data.totalSalesHeadroom, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(data.totalOptimisedSales, 1)}
                    </Typography>
                }
                comparisonLabel={`Optimised sales in the past 12 months ${selectedRange?.name} - ${selectedStore?.name}`}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(data.totalEstimatedSales, 1)}
                    </Typography>
                }
                differenceLabel={`Estimated sales in the past 12 months ${selectedRange?.name} - ${selectedStore?.name}`}
            />
        </Box>
    );
};

export default ProductStoreSales;
