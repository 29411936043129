import { selectSelectedPartner, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import React from "react";
import { useAppSelector } from "store";

const Subtitle: React.FC = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedRange = useAppSelector(selectSelectedRange);
    const selectedStore = useAppSelector(selectSelectedStore);
    return (
        <>
            Evaluate the opportunity for {selectedRange?.name} in the {selectedPartner?.name} {selectedStore?.name} store.
        </>
    );
};

export default Subtitle;
