import React from "react";

import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { showStoreRangeFilters } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";

import Chapter from "pages/customer/tools/product/insight/Chapter";
import Subchapter from "pages/customer/tools/product/insight/Subchapter";

import ProductMix from "./productMix/ProductMix";
import ProductMixSubtitle from "./productMix/Subtitle";
import StoreInsight from "./storeInsight/StoreInsight";
import StoreInsightSubtitle from "./storeInsight/Subtitle";
import Subtitle from "./Subtitle";

const StoreOpportunities: React.FC = () => {
    const dispatch = useAppDispatch();
    const selectedStore = useAppSelector(selectSelectedStore);

    React.useEffect(() => {
        if (!selectedStore) {
            dispatch(showStoreRangeFilters());
        }
    }, [dispatch, selectedStore]);

    return (
        <>
            {selectedStore && (
                <Chapter
                    title="Store opportunities"
                    subtitle={<Subtitle />}
                    dataCy="store-opportunities-chapter"
                >
                    <Subchapter
                        title="Store insight"
                        subtitle={<StoreInsightSubtitle />}
                        dataCy="store-insight-subchapter"
                    >
                        <StoreInsight />
                    </Subchapter>
                    <Subchapter
                        title="Product mix"
                        subtitle={<ProductMixSubtitle />}
                        dataCy="product-mix-subchapter"
                    >
                        <ProductMix />
                    </Subchapter>
                </Chapter>
            )}
        </>
    );
};

export default StoreOpportunities;
