import React from "react";

import { useAppSelector } from "store";
import { StoreRangeFilterStep, selectActiveStep } from "modules/customer/tools/product/storeRangeFilters/storeRangeFiltersSlice";
import Stores from "./stores/Stores";
import Ranges from "./ranges/Ranges";

const Content: React.FC = () => {
    const activeStep = useAppSelector(selectActiveStep);

    return (
        <>
            {activeStep === StoreRangeFilterStep.SelectStore && (<Stores />)}
            {activeStep === StoreRangeFilterStep.SelectRange && (<Ranges />)}
        </>
    );
};

export default Content;
