import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RootState } from "store";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectProducts, selectReferenceDate, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { loadRetailCentreClassification } from "./retailCentreClassification";
import { loadStoreMonthlySales, StoreMonthlySales } from "./storeMonthlySales";
import { DataWrapper } from "domain/dataWrapper";
import { numberSortExpression, SortDirection } from "utils/sortUtils";
import { SalesMixTreemapRow } from "pages/customer/tools/product/insight/storeOpportunities/productMix/SalesMixTreemap";

interface StoreOpportunitiesState {
    isLoading: boolean,
    hasErrors: boolean,
    retailCentreClassification: String,
    storeMonthlySales: StoreMonthlySales[]
}

const initialState: StoreOpportunitiesState = {
    isLoading: false,
    hasErrors: false,
    retailCentreClassification: "",
    storeMonthlySales: []
};

interface LoadStoreOpportunitiesResponse {
    retailCentreClassification: String,
    storeMonthlySales: StoreMonthlySales[]
}

const storeOpportunitiesSlice = createSlice({
    name: "customer/tools/product/storeOpportunities",
    initialState,
    reducers: {
        clearRetailCentreClassification: (state) => {
            state.retailCentreClassification = initialState.retailCentreClassification;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadStoreOpportunities.pending, (state: StoreOpportunitiesState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.retailCentreClassification = initialState.retailCentreClassification;
            state.storeMonthlySales = initialState.storeMonthlySales;
        });
        builder.addCase(loadStoreOpportunities.rejected, (state: StoreOpportunitiesState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.retailCentreClassification = initialState.retailCentreClassification;
            state.storeMonthlySales = initialState.storeMonthlySales;
        });
        builder.addCase(loadStoreOpportunities.fulfilled, (state: StoreOpportunitiesState, action: PayloadAction<LoadStoreOpportunitiesResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.retailCentreClassification = action.payload.retailCentreClassification;
            state.storeMonthlySales = action.payload.storeMonthlySales;
        });
    }
});

export const loadStoreOpportunities = createAppAsyncThunk(
    "customer/tools/product/store/loadStoreOpportunities",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const referenceDate = selectReferenceDate(state);
            const selectedStore = selectSelectedStore(state);
            const selectedRange = selectSelectedRange(state);

            const retailCentreClassificationPromise = thunkAPI.dispatch(loadRetailCentreClassification(selectedStore));
            const monthlySalesPromise = thunkAPI.dispatch(loadStoreMonthlySales(referenceDate, selectedStore?.id, selectedRange));

            const results = await Promise.all([retailCentreClassificationPromise, monthlySalesPromise]);
            const loadStoreOpportunitiesResponse: LoadStoreOpportunitiesResponse = {
                retailCentreClassification: results[0],
                storeMonthlySales: results[1]
            };
            return loadStoreOpportunitiesResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Store Opportunities.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

// export const {

// } = storeOpportunitiesSlice.actions;

export const clearStoreOpportunities = (): AppThunk => async (dispatch) => {
    dispatch(storeOpportunitiesSlice.actions.clearRetailCentreClassification());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.hasErrors;
};

export const selectRetailCentreClassification = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.retailCentreClassification;
};

export const selectStoreMonthlySales = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.storeMonthlySales;
};

export const selectEstimatedSalesMix = createSelector(
    (state: RootState) => selectProducts(state),
    (products) => {
        const { data: productsData, isLoading, hasErrors } = products;
        const estimatedSalesMix: DataWrapper<SalesMixTreemapRow[]> = {
            isLoading,
            hasErrors,
            data: []
        };
        if (isLoading || hasErrors || productsData.length === 0) {
            return estimatedSalesMix;
        }

        const totalEstimatedSales = productsData.reduce((total, current) => total + current.sales.estimatedSales, 0);

        estimatedSalesMix.data = [...productsData]
            .sort((a, b) => numberSortExpression(a.sales.estimatedSales, b.sales.estimatedSales, SortDirection.DESC))
            .map((product, index) => ({
                name: product.name,
                colourIndex: index % 8,
                type: "Subcategory",
                parent: undefined,
                salesValue: product.sales.estimatedSales,
                percentageOfTotalSales: 100 * (product.sales.estimatedSales / totalEstimatedSales)
            }));
        return estimatedSalesMix;
    }
);

export const selectOptimisedSalesMix = createSelector(
    (state: RootState) => selectProducts(state),
    (products) => {
        const { data: productsData, isLoading, hasErrors } = products;
        const estimatedSalesMix: DataWrapper<SalesMixTreemapRow[]> = {
            isLoading,
            hasErrors,
            data: []
        };
        if (isLoading || hasErrors || productsData.length === 0) {
            return estimatedSalesMix;
        }

        const totalEstimatedSales = productsData.reduce((total, current) => total + current.sales.optimisedSales, 0);

        estimatedSalesMix.data = [...productsData]
            .sort((a, b) => numberSortExpression(a.sales.optimisedSales, b.sales.optimisedSales, SortDirection.DESC))
            .map((product, index) => ({
                name: product.name,
                colourIndex: index % 8,
                type: "Subcategory",
                parent: undefined,
                salesValue: product.sales.optimisedSales,
                percentageOfTotalSales: 100 * (product.sales.optimisedSales / totalEstimatedSales)
            }));
        return estimatedSalesMix;
    }
);


export default storeOpportunitiesSlice;
