import { AppThunk } from "appThunk";
import { logError } from "modules/helpers/logger/loggerSlice";
import { Partner } from "modules/customer/tools/product/partner";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { ResultSet } from "@cubejs-client/core";
import { ProductSales } from "modules/customer/tools/product/productSales";
import { Store } from "modules/customer/tools/product/store";

export class Range {
    public readonly id: number;
    public readonly name: string;
    public readonly productCategory: string;
    public readonly sales: ProductSales;

    constructor(
        id: number,
        name: string,
        productCategory: string,
        clientSourcedSales: number,
        estimatedSales: number,
        optimisedSales: number,
        salesHeadroom: number,
        confidenceInterval?: number
    ) {
        this.id = id;
        this.name = name;
        this.productCategory = productCategory;
        this.sales = new ProductSales(
            String(id),
            name,
            clientSourcedSales,
            estimatedSales,
            optimisedSales,
            salesHeadroom,
            confidenceInterval
        );
    }
}

export const loadRanges = (referenceDate: DateTime, partner?: Partner, candidateStore?: Store): AppThunk<Promise<Range[]>> => async (dispatch) => {
    try {
        if (!partner) {
            return [];
        }
        const startDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day');
        const endDate = referenceDate.endOf('day');
        const query = {
            measures: [
                "ProductSalesEstimate.SumClientSourcedSales",
                "ProductSalesEstimate.SumEstimatedSales",
                "ProductSalesEstimate.SumOptimisedSales",
                "ProductSalesEstimate.SumSalesHeadroom",
            ],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [startDate, endDate]
            }],
            dimensions: [
                "D_ProductCategory.PK_ProductCategory",
                "D_ProductCategory.ProductCategory1",
                "D_ProductCategory.ProductCategory2",
            ],
            filters: [{
                member: "D_Store.Group",
                operator: "equals",
                values: [partner.name]
            }],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
        };

        if (candidateStore) {
            query.filters.push({
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [String(candidateStore.id)]
            });
        }

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const products = resultSet.rawData().map(row => new Range(
            Number(row["D_ProductCategory.PK_ProductCategory"]),
            row["D_ProductCategory.ProductCategory1"],
            row["D_ProductCategory.ProductCategory2"],
            Number(row["ProductSalesEstimate.SumClientSourcedSales"]),
            Number(row["ProductSalesEstimate.SumEstimatedSales"]),
            Number(row["ProductSalesEstimate.SumOptimisedSales"]),
            Number(row["ProductSalesEstimate.SumSalesHeadroom"])
        ));
        return products;
    } catch (error) {
        dispatch(logError("Error loading Ranges.", error));
        throw error;
    }
};
